import "./layout.css";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "../components/navbar";
import "./layout.css";
import Footer from "../components/footer";
const BasicLayout = ({ noUser }) => {
  return (
    <>
      <Container fluid className="p-0">
        <Row>
          <Col className="navbar-container">
            <Header noUser={noUser} />
          </Col>
        </Row>
        <div className="app-content">
          <Row>
            <Outlet />
          </Row>
        </div>
        <div className="footer-section">
          <Row>
            <Col>
              <Footer />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default BasicLayout;
