import { ProgressBar } from "react-bootstrap";

const CustomProgressBar =({progress})=>{
    return (
        <>
              <ProgressBar animated label={"Uploading..."} now={progress} />
        </>
    )
}

export default CustomProgressBar;