import React, { useContext, useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import UserContext from "../../contexts/user.context";
import "./profilePage.css";
import BackButton from "../../components/backButton";
import { useMutation } from "react-query";
import UserAPI from "../../services/apis/userAPI";
import CountrySelect from "../../components/countryAutocomplete";
const ProfilePage = () => {
  const [amazonProfileName, setAmazonProfileName] = useState();
  const { user, refreshUser } = useContext(UserContext);

  const amazonProfileNameMutation = useMutation(
    (data) => UserAPI.saveAmazonName(data),
    {
      onSuccess: () => {
        refreshUser();
      },
    }
  );

  const amazonReviewCountryMutation = useMutation(
    (data) => UserAPI.saveAmazonReviewCountry(data),
    {
      onSuccess: () => {
        // refreshUser();
      },
    }
  );

  const handleCountryChange=(value)=>{
    amazonReviewCountryMutation.mutate({amazonReviewCountry:value})
  }

  const handleAPNInput = ({ target }) => {

    setAmazonProfileName(target.value);
  };
  return (
    <Container fluid className="profile-page-info p-5">
      <Row className="justify-content-center">
        <Col sm={6}>
          <BackButton />
          
          {user ? (
            <Card>
              <Card.Header>
                <Card.Title className="mt-2">Contact Information</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form className="m-3">
                  <Form.Group className="mb-3" controlId="input1">
                    <Form.Label>
                      <h6>Name</h6>
                    </Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      readOnly
                      defaultValue={user.name}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="input2">
                    <Form.Label>
                      <h6>Email</h6>
                    </Form.Label>
                    <Form.Control
                      size="md"
                      type="email"
                      readOnly
                      defaultValue={user.email}
                    />
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label >
                      <h6>Country Where You Posted Review</h6>
                    </Form.Label>
                    <CountrySelect className="mb-2" defaultCountry={user.amazonReviewCountry} onCountrySelect={handleCountryChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      <h6>Amazon Reviewer Name</h6>
                    </Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      isValid
                      readOnly={user?.amazonProfileName}
                      placeholder="Provide the name which will be shown along with your review on Amazon"
                      defaultValue={user?.amazonProfileName}
                      onInput={handleAPNInput}
                    />
                    <Form.Control.Feedback type="valid">
                      <p className="light-text">
                        Provide the name which will be shown along with your
                        review on Amazon
                      </p>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      <p className="light-text">
                        Warning: This can only be saved once
                      </p>
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      <p className="light-text">
                        Provide the name which will be shown along with your
                        review on Amazon
                      </p>
                      <p className="light-text">
                        Warning: This can only be saved once
                      </p>
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    // disabled={user?.amazonProfileName}
                    className="mt-2"
                    onClick={() =>{
                      if(amazonProfileName) amazonProfileNameMutation.mutate({ amazonProfileName })
                    }}
                  >
                    Save Account Info
                  </Button>
                </Form>
              </Card.Body>
              <Card.Footer className="pb-3">
                To update your profile details email us
                support@kdpcompliment.com
              </Card.Footer>
            </Card>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage;
