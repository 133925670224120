import React, { useEffect, useState } from "react";
import { StripeAPI } from "../services/apis/stripeAPI";
import { useMutation } from "react-query";
const StripePricingTable = ({clientReferenceId}) => {
    const [clientSecret, setClientSecret] = useState("");

    const createSessionMutation = useMutation(
      () => StripeAPI.createCustomerSession(),
      {
        onSuccess: (data, variables, context) => {
          setClientSecret(data.data.client_secret);
        },
      }
    );
  
    useEffect(() => {
      createSessionMutation.mutate();
    }, []);
  
    return (
      <>
        {clientSecret && (
            <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            customer-session-client-secret={clientSecret}
          ></stripe-pricing-table>
        )}
      </>
    );
};
export default StripePricingTable;
