import axios from 'axios'
const FilesAPI ={
    upload: async (formData, folder, onUploadProgress)=>{
        const url = '/file/upload/'+folder;
        const instance =axios.create({
            withCredentials: true,
            timeout:30000,
            baseURL: process.env.REACT_APP_SERVER_URL,
            headers: {
                'Content-Type':'multi-part/formdata'
            },
        });
        const requestConfig = {
            onUploadProgress,           
        };
        const response = await instance.post(url, formData, requestConfig);
        if(response?.data){
            return response.data;
        }
        return response;
    }
}

export default FilesAPI;