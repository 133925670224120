import {  Row, Col, Image } from "react-bootstrap";

const FeatureList = ({ src, text }) => {
  return (
    <Row>
      <Col sm={1}>
        <Image width="200%" src={src} />
      </Col>
      <Col sm={11}>{text}</Col>
    </Row>
  );
};

export default FeatureList;