import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import AssignmentAPI from "../services/apis/assignmentAPI";
import "./reviewForm.css";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/user.context";
const VERIFIED_PRIZE_LIST = [
  { label: "Free Promotion", points: 0 },
  { label: "$0.99", points: 300 },
  { label: "$1.99", points: 600 },
  { label: "$2.99", points: 900 },
  { label: "$3.99", points: 1200 },
  { label: "$4.99", points: 1500 },
];

const ReviewForm = ({ bookData, successCallback }) => {
  const navigate = useNavigate();
  const {user, refreshUser} = useContext(UserContext);
  const [assignmentFormData, setAssignmentFormData] = useState({
    turnaroundDays: 0,
    reviewType: "",
    kindlePrice: "",
    turnaroundDaysPoints: 0,
    verifiedPoints: 0,
  });

  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    setTotalPoints(
      1050 +
        assignmentFormData.turnaroundDaysPoints +
        assignmentFormData.verifiedPoints
    );
  }, [assignmentFormData]);

  const submitAssignment = useMutation(
    (assignmentForm) => AssignmentAPI.create(assignmentForm),
    {
      onSuccess: (data, variables, context) => {
        refreshUser();
        successCallback();
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const assignmentForm = {
      book: bookData._id,
      points: totalPoints,
      ...assignmentFormData,
    };
    
    submitAssignment.mutate(assignmentForm);
  };

  const handleEarnSnapsRedirect = (e) => {
    e.preventDefault();
    navigate("/reader");
  };

  return (
    <Container className="m-4">
      <Row>
        <Col>
          <Form className="my-3">
            <hr className="my-2 me-4" />
            <h6 className="light-title">
              <b>CHOOSE YOUR REVIEW OPTIONS</b>
            </h6>
            <hr className="my-2 me-4" />
            <div>
              <Row className="my-3">
                <Col>
                  <h5 className="time-title">Turnaround Time</h5>
                </Col>
                {assignmentFormData.turnaroundDaysPoints ? (
                  <Col>Charge: {assignmentFormData.turnaroundDaysPoints}</Col>
                ) : null}
              </Row>
              <div className="time-title-option">
                <Form.Check
                  inline
                  label="3"
                  type="radio"
                  id="radioSelectDays1"
                  checked={assignmentFormData.turnaroundDays === 3}
                  onChange={() => {
                    setAssignmentFormData({
                      ...assignmentFormData,
                      turnaroundDays: 3,
                      turnaroundDaysPoints: 0,
                    });
                  }}
                />
                <Form.Check
                  inline
                  label="2"
                  type="radio"
                  id="radioSelectDays1"
                  checked={assignmentFormData.turnaroundDays === 2}
                  onChange={() => {
                    setAssignmentFormData({
                      ...assignmentFormData,
                      turnaroundDays: 2,
                      turnaroundDaysPoints: 100,
                    });
                  }}
                />
                <Form.Check
                  inline
                  label="1"
                  name="selectDays"
                  type="radio"
                  id="radioSelectDays1"
                  checked={assignmentFormData.turnaroundDays === 1}
                  onChange={() => {
                    setAssignmentFormData({
                      ...assignmentFormData,
                      turnaroundDays: 1,
                      turnaroundDaysPoints: 200,
                    });
                  }}
                />
                <b>Days</b>
              </div>
              <Col sm={10} className="mt-2 mb-3">
                <p>
                  Choose the number of days your reader is given to read your
                  book and turn in their review.
                </p>
              </Col>
              <hr className="mt-4 me-4" />
            </div>
            <Row>
              <div>
                {bookData.standard ? (
                  <div className="standard-option">
                    <Form.Check className="my-3">
                      <Form.Check.Input
                        className="mt-2 me-2"
                        type="checkbox"
                        checked={assignmentFormData.reviewType === "standard"}
                        onChange={() => {
                          setAssignmentFormData({
                            ...assignmentFormData,
                            reviewType: "standard",
                            verifiedPoints: 0,
                            kindlePrice: "",
                          });
                        }}
                      />
                      <Form.Label>
                        <h4>Standard</h4>
                      </Form.Label>
                      <Row>
                        <Form.Text>
                          Your reader will download the provided file and leave
                          a free review on amazon
                        </Form.Text>
                      </Row>
                    </Form.Check>
                  </div>
                ) : null}
                {bookData.forVerifiedPurchase ? (
                  <Form.Check className="my-3">
                    <Form.Check.Input
                      className="mt-2 me-2"
                      type="checkbox"
                      checked={assignmentFormData.reviewType === "verified"}
                      onChange={() => {
                        setAssignmentFormData({
                          ...assignmentFormData,
                          reviewType: "verified",
                        });
                      }}
                    />
                    <Form.Label className="verified-option">
                      <Row>
                        <Col sm={"auto"}>
                          <h4>Verified Purchase</h4>
                        </Col>
                        {assignmentFormData.verifiedPoints ? (
                          <Col>Charge: {assignmentFormData.verifiedPoints}</Col>
                        ) : null}
                      </Row>
                    </Form.Label>
                    {assignmentFormData.reviewType === "verified" ? (
                      <div>
                        <h6>Kindle Price:</h6>
                        {VERIFIED_PRIZE_LIST.map((val, index, list) => {
                          return (
                            <Form.Check
                              inline
                              label={val.label}
                              type="radio"
                              id={val.label}
                              key={val.label}
                              checked={
                                assignmentFormData.kindlePrice === val.label
                              }
                              onChange={() => {
                                setAssignmentFormData({
                                  ...assignmentFormData,
                                  kindlePrice: val.label,
                                  verifiedPoints: val.points,
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                    ) : null}

                    <Row>
                      <Form.Text>
                        Your reader will purchase your book and leave a Verified
                        Purchase review.
                      </Form.Text>
                    </Row>
                  </Form.Check>
                ) : null}
                {bookData.onAudible ? (
                  <Form.Check className="my-5">
                    <Form.Check.Input
                      className="mt-2 me-2"
                      name="purchaseType"
                      type="checkbox"
                      checked={assignmentFormData.reviewType === "audible"}
                      onChange={() => {
                        setAssignmentFormData({
                          ...assignmentFormData,
                          reviewType: "audible",
                          verifiedPoints: 0,
                          kindlePrice: "",
                        });
                      }}
                    />
                    <Form.Label className="audible-option">
                      <h4>Audible</h4>
                    </Form.Label>
                    <Row>
                      <Form.Text>
                        Your reader will listen your book on Audible and leave a
                        review there
                      </Form.Text>
                    </Row>
                  </Form.Check>
                ) : null}
                {bookData.forKindleUnlimited ? (
                  <Form.Check className="my-5 kindle-option">
                    <Form.Check.Input
                      className="mt-2 me-2"
                      name="purchaseType"
                      type="checkbox"
                      checked={assignmentFormData.reviewType === "kindle"}
                      onChange={() => {
                        setAssignmentFormData({
                          ...assignmentFormData,
                          reviewType: "kindle",
                          verifiedPoints: 0,
                          kindlePrice: "",
                        });
                      }}
                    />
                    <Form.Label>
                      <h4>Kindle Unlimited</h4>
                    </Form.Label>
                    <Row>
                      <Form.Text>
                        Readers with a Kindle Unlimited subscription can review
                        your book. Your readers will read your book using Kindle
                        Unlimited.
                      </Form.Text>
                    </Row>
                  </Form.Check>
                ) : null}
              </div>
            </Row>
            <Row className="justify-content-center text-center">
              <h4>
                <b>
                  Total:
                  {totalPoints} Points
                </b>
              </h4>
            </Row>
            <Row className="justify-content-center text-center my-2">
              {user?.points < totalPoints  ? (
                <>
                  <Button
                    as={Col}
                    sm={4}
                    type="primary"
                    onClick={handleEarnSnapsRedirect}
                  >
                    EARN SNAPS
                  </Button>
                </>
              ) : (
                <>
                  <Button as={Col} sm={4} type="primary" disabled={!assignmentFormData.reviewType || !assignmentFormData.turnaroundDays} onClick={handleSubmit}>
                    Submit
                  </Button>
                </>
              )}
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ReviewForm;
