import api from './configs/axiosConfig';

const AssignmentAPI = {
    create: async (assignmentForm)=>{
        let response = await api.post('/assignments',assignmentForm);
        console.log(assignmentForm);
        return response.data;

    },
    getById:async(id)=>{
        return api.get('assignments/'+id);
    },
    getAll: async ()=>{
        return api.get('assignments');
    },
    pick: async (assignmentId)=>{
        return api.patch('assignments/pick/'+assignmentId)
    },
    cancel:async (assignmentId)=>{
        return api.patch('assignments/cancel-pick/'+assignmentId)
    },
    submit: async ({assignmentId, reviewForm})=>{
        return api.patch('assignments/'+assignmentId+'/submit', reviewForm)
    },
    updateTodos: async ({assignmentId, todos})=>{
        return api.patch('assignments/'+assignmentId+'/todos',todos);
    }
}

export default AssignmentAPI;