import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Separator from "../../components/separator";
import AuthAPI from "../../services/apis/authAPI";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const requestMutation = useMutation(
    (data) => AuthAPI.requestResetPassword(data),
    {
      onSuccess: async (data, variables, context) => {
        setStatus(data.data);
        if(data.data.success){
            redirectAfter()
        }
      },
    }
  );

  const redirectAfter = () => {
    setTimeout(() => {
      navigate("/auth/login");
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    const formEntries = new FormData(e.target).entries();
    for (const entry of formEntries) {
      data[entry[0]] = entry[1];
    }
    requestMutation.mutate(data);
  };

  return (
    <div>
      <Container fluid className="p-5">
        <Row>
          <Col className="mx-auto" md={3}>
            <h2 className="login-heading">Forgot Password?</h2>
            <p className="login-heading">
              Remember your password?{" "}
              <a
                href="/auth/login"
                style={{ textDecoration: "none", color: "#3e38f5" }}
              >
                Login Here
              </a>
            </p>
            <Separator text={""} />
            <Form className="mt-4" onSubmit={handleSubmit}>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="E.g. johndoe@gmail.com"
                />
                <Form.Text className="text-muted">
                  Please enter registered email
                </Form.Text>
              </Form.Group>

              <Button
                style={{ backgroundColor: "#3e38f5" }}
                className="login-button mt-2 mb-3"
                variant="primary"
                type="submit"
              >
                Reset Password
              </Button>
              <div className="text-center">
                {requestMutation.isLoading ? (
                  <p>Loading...</p>
                ) : requestMutation.isError ? (
                  <p className="error">
                    Error! {requestMutation.error?.response?.data?.message}
                  </p>
                ) : requestMutation.isSuccess ? (
                  <>
                    {status.success ? (
                      <>
                        <p className="success">
                          Password reset link is sent to your email!
                        </p>
                        <p>Redirecting to login...</p>
                      </>
                    ) : (
                      <>
                        <p className="error">{status.message}</p>
                      </>
                    )}
                  </>
                ) : null}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
