import axios from "axios";

const instance = axios.create({
    withCredentials: true,
    timeout:30000,
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
        'Content-Type':'application/json'
    },
});


export default instance;