import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from '@mui/icons-material/Instagram';
import { Image } from "react-bootstrap";
const Footer = () => {
  return (
    <div>
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span className="light-text">
            Get connected with us on social networks:
          </span>
        </div>

        <div>
          <a target="_blank" href="https://www.facebook.com/Kdpcompliment" className="me-4 text-reset">
            <FacebookIcon />
          </a>
          <a target="_blank" href="https://www.instagram.com/kdpcompliment?igsh=MW0yenc3NTdvZGYxeA==" className="me-4 text-reset">
            <InstagramIcon />
          </a>
        </div>
        
      </section>
      <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.00)" }}
        >
          <a className="text-reset fw-bold" href="https://kdpcompliment.com/">
            <Image width="4%" src="/kdp-bird-trans.ico" />
          </a>
        </div>
    </div>
  );
};
export default Footer;
