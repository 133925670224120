import React, { useState } from 'react';
import './dayCounter.css';

const DayCounter = ({ n, lastDate }) => {
  // Create an array of numbers from 1 to n
  const days = Array.from({ length: n }, (_, i) => i + 1);

  // Calculate the start date based on the last date
  const [startDate] = useState(
    new Date(lastDate.getTime() - (n - 1) * 24 * 60 * 60 * 1000)
  );

  // Format the date string in the desired format
  const formatDate = (date) => {
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="day-counter">
      {days.map((day) => (
        <div key={day} className={`day-item ${day === n - 1 || day === n ? 'due' : ''}`}>
          {formatDate(new Date(startDate.getTime() + day * 24 * 60 * 60 * 1000))}
          {day === n - 1 || day === n ? <div className="due-label">Due</div> : null}
        </div>
      ))}
    </div>
  );
};

export default DayCounter;