import { Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from "react-pro-sidebar";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BookIcon from "@mui/icons-material/Book";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import "./sidebar.css";
const SidebarWrapper = ({ userDetails }) => {
  return (
    <>
      <Sidebar backgroundColor="antiquewhite" className=" sidebar">
        <Menu
          className="menu-text"
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              return {
                height: "38px",
              };
            },
          }}
        >
          <MenuItem
            icon={<FreeBreakfastIcon color="primary" />}
            component={<Link to="/author" />}
          >
            <b>Author</b>
          </MenuItem>
          {userDetails?.data.userBooks.map((book, index, books) => {
            return book.approved ? (
              <MenuItem
                icon={<BookmarkAddedIcon sx={{ color: "orange" }} />}
                key={book._id}
                component={<Link to={`/book/view/${book._id}`} />}
              >
                {book.title}
              </MenuItem>
            ) : (
              <MenuItem
                icon={<BorderColorIcon sx={{ color: "orange" }} />}
                key={book._id}
                component={<Link to={`/book/edit/${book._id}`} />}
              >
                {book.title}
              </MenuItem>
            );
          })}
          <MenuItem
            component={<Link to="/book/add" />}
            icon={<LibraryAddIcon color="success" />}
          >
            Add book
          </MenuItem>
          <MenuItem
            component={<Link to="/reader" />}
            icon={<BookIcon color="primary" />}
            className="mt-4"
          >
            <b>Reader</b>
          </MenuItem>

          { userDetails?.data?.userAssignments?.map((assignment, index, list) => {
                return assignment.approved ? (
                  <MenuItem
                    key={assignment._id}
                    component={<Link to={`/assignments/${assignment._id}`} />}
                    icon={<AssignmentTurnedInIcon color="primary" />}
                  >
                    {assignment.book.title}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={assignment._id}
                    component={<Link to={`/assignments/${assignment._id}`} />}
                    icon={<TurnedInNotIcon color="primary" />}
                  >
                    {assignment.book.title}
                  </MenuItem>
                );
              })
            }
          <MenuItem
            component={<Link to="/library/browse" />}
            icon={<LibraryAddIcon color="success" />}
          >
            Browse Library
          </MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
};

export default SidebarWrapper;
