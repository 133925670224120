import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import "./library.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AssignmentAPI from "../../services/apis/assignmentAPI";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../contexts/user.context";

const Library = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {refreshUser} = useContext(UserContext);

  const [display, setDisplay] = useState();
  const query = useQuery("searhAssignments", AssignmentAPI.getAll);
    
  const pickMutation = useMutation((assignmentId)=>AssignmentAPI.pick(assignmentId),{
    onSuccess:(data,variables,context)=>{
        queryClient.fetchQuery('userDetails');
        console.log(variables)
        refreshUser();
        
        navigate('/assignments/'+variables)
    }
  });

  useEffect(() => {
    let updateDisplay = {};
    if (!query?.data) {
      return;
    }
    for (let assignment of query?.data?.data) {
      updateDisplay[assignment._id] = "display-none";
    }
    setDisplay(updateDisplay);
  }, [query.data]);

  const pickClick=(id)=>{
    pickMutation.mutate(id);
  }


  if (query.isLoading) {
    return <h6>Loading...</h6>;
  }

  if (query.isError) {
    return <h6>Try again!</h6>;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Image className="m-4 library-logo" src="/library.png" />
        </Col>
      </Row>
      <Row>
        <h3 className="m-4">Filters</h3>
        <hr className="hr" />
      </Row>
      <Row>
        <Col md={8}>
          <Row className="px-4">Choose a book</Row>
          <Row>
            {query.data.data.map((assignment, index, list) => {
              return (
                <Col key={assignment._id} md={6}>
                  <Card
                    onMouseEnter={() =>
                      setDisplay({
                        ...display,
                        [assignment._id]: "display-block",
                      })
                    }
                    onMouseLeave={() =>
                        setDisplay({
                          ...display,
                          [assignment._id]: "display-none",
                        })}
                    style={{borderWidth:"4px"}}
                    className="book-card my-2"
                  >
                    <Card.Body>
                      <Row>
                        <Col sm={4} className="text-center">
                          <Image
                            className="cover-image"
                            src={assignment.book.cover}
                            rounded
                          />
                          <b className="mt-2 points">P {assignment.points}</b>
                        </Col>
                        <Col sm={8}>
                          <h4 className="title-text">
                            {assignment.book.title}
                          </h4>
                          <h6>{assignment.book.genre.name}</h6>
                          {assignment.reviewType === "verified" ? (
                            <>
                              <h6 className="grey-text">
                                Requires Purchase{" "}
                                <span className="price-text">
                                  {assignment.kindlePrice}
                                </span>
                              </h6>
                            </>
                          ) : (
                            <>
                              <h6 className="grey-text">Free Download </h6>
                              {assignment.reviewType === "kindle" ? (
                                <>
                                  <h6 className="grey-text">
                                    Requires{" "}
                                    <span className="price-text">
                                      Kindle Unlimited
                                    </span>
                                  </h6>
                                </>
                              ) : (
                                <>
                                  {assignment.reviewType === "audible" ? (
                                    <>
                                      <h6 className="grey-text">
                                        Requires{" "}
                                        <span className="price-text">
                                          Audible
                                        </span>
                                      </h6>
                                    </>
                                  ) : null}
                                </>
                              )}
                            </>
                          )}
                          <h6 className="grey-text mb-5">
                            Due{" "}
                            <span className="date-text">
                              {new Date(
                                new Date().setDate(new Date().getDate() + assignment.turnaroundDays)
                              ).toLocaleString("en-US", {
                                day: "2-digit",
                                month: "short",
                              })}
                            </span>
                          </h6>
                          <Col sm={12}>
                          <Container className="d-flex justify-content-end">
                           
                          <div
                            className={
                              assignment  && display && display[assignment._id]
                                ? display[assignment._id]
                                : "display-none"
                            }
                          >
                            <Button className="pick-button" onClick={()=>pickClick(assignment._id)}>Pick</Button>
                          </div>
                            
                          </Container>
                          </Col>
                          
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col>
          <Row>All Genres</Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Library;
