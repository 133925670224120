import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";
import "./home.css";
import { useNavigate } from "react-router-dom";
import useRedirectIfLoggedin from "../hooks/useRedirectIfLoggedin";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Header from "../components/navbar";
import FeatureList from "../components/featureList";

const Home = () => {
  const ADV = [
    "Upload an unlimited number of books & audiobooks",
    "Get an unlimited number of book reviews",
    "Upload all of your ACX codes and get your audiobooks reviewed",
    "2,000 points bonus when you sign up",
    "24/7 customer support",
    "Cancel at any time",
  ];
  const QUESTIONS = [
    "Are there any requirements that I must meet before signing up?",
    "Can I receive reviews directly on my Amazon.com book listings?",
    "Do you offer a free trial?",
    "If I cancel, what happens to my reviews and snaps?",
    "Is this not Review swapping?",
    "What's your refund policy?",
    "Does this not fall under paying for reviews, which is prohibited?",
    "How do I cancel my subscription?",
  ];

  const ANSWERS = [
    "Yes, authors interested in the 'unlimited books and 'unlimited books and audiobooks plans must have an Amazon account that meets Amazon’s reviewing requirements. To be eligible to leave reviews, you must have spent a minimum of $50 on your account within the last 12 months (or the equivalent in your local currency). Authors opting for the \"Unlimited Audiobooks\" plan are not required to meet this. Although they should have a US or UK Audible account where they can redeem ACX codes, listen to audiobooks, and leave reviews.",
    "Yes, all reviews are posted directly to your book listings by our reviewers.",
    "Yes, we offer a 14-day free trial. Try it out for two weeks risk-free; we are sure you will love our platform! However, if for whatever reason you wish to not continue, simply cancel your subscription before the 14th day to avoid payment.",
    "If you cancel your membership or free trial, your reviews will remain on Amazon.com. We'll keep a record of your earned snaps so that should you decide to return to Pubby in the future, they'll be waiting for you!",
    "No, we have specifically built our system to ensure that authors are not review swapping on our platform. For example, if person A reviews one of person B's books, as soon as they do that, person B will not be able to see any of person A's books in our library; this will prevent a direct review swap. In addition to that, authors on our platform do not get to interact with each other. We have carefully ensured that our platform is in line with Amazon's community guidelines for reviews.",
    "We offer a full refund within 48 hours of your most recent payment, no questions asked. Send us an email at support@kdpcompliment.com to receive a refund.",
    "No, you are not paying for reviews. You are paying a monthly subscription to access the platform, which gives your book the visibility to be anonymously reviewed by another author. This is why you do not pay 'per review' on our platform. Our platform gives authors the space and opportunity to acquire an unlimited number of honest reviews from other authors who are passionate about their book and niche.",
    "Canceling your subscription is easy to do at any time through our website and does not require contacting support. You can click 'Account Settings' once logged in, then click 'Cancel Subscription' on the 'Settings' page.",
  ];

  const REVIEW_QOUTES = [
    [
      "I can't recommend this book review service enough! Their platform has helped me gain valuable feedback on my latest novel. It's a must-have resource for any author looking to refine their craft.",
      "I've tried several book review services in the past, but none compare to the quality provided by this platform.  Their reviewers are knowledgeable and offer thoughtful analysis that has been instrumental in shaping my book for the better.",
      "This book review service is a game-changer for indie authors like myself. Their reviewers are honest, unbiased, and delivered in a timely manner. I've seen a significant increase in book sales and reader engagement since using their services.",
      "I've been using this book review service for years, and I continue to be impressed by the caliber of reviews I've got.",
    ],
    [
      "I was blown away by the professionalism and expertise of the reviewers on this platform. Their detailed critiques provided me with invaluable insights into areas of improvement for my book. Thank you for your exceptional service!",
      "I'm incredibly impressed with the professionalism and attention to detail demonstrated by this book review platform. Their reviewers take the time to truly understand the nuances of each book, resulting in reviews that are insightful and impactful.",
      "If you're looking for honest and unbiased book reviews, look no further than this platform. Their reviewers have a keen eye for detail and provide feedback that is both constructive and encouraging. I couldn't be happier with the results!",
    ],
    [
      "As a first-time author, I was nervous about getting feedback on my manuscript. But this book review service exceeded my expectations!Their constructive service was delivered with kindness and helped me elevate my writing to new heights.",
      "This platform is worth every penny! Their reviews are thorough, well-written, and provide actionable insights that have helped me refine my storytelling techniques. I highly recommend their services to any author serious about improving their craft.",
      "I can't thank this book review platform enough for the support they've provided throughout my publishing journey. Their reviews have not only helped me polish my manuscripts but have also boosted my confidence as a writer. I'm forever grateful for their expertise and guidance.",
    ],
  ];
  const FEATURES_TEXT = [
    "Unlimited numbers of books & audiobooks compliments (reviews). No charge or fee per compliment. Pay one fee per month and get unlimited compliments.",
    "Our Author Dashboard gives you all the updates of your readers and compliments. Say goodbye to spam reviews!",
    "...fast option, authors can contribute extra points for a 24-hour turnaround.",
    "If you have any questions about our service send us a live chat message or text to support@kdpcompliment.com and a member of customer support will get back to you within 24 hours.",
    "Cancel your subscription at any time or change your plan through the available options and choose the most suitable to meet your budget.",
  ];
  const FEATURES_HEADING = [
    "Unlimited Compliments (reviews)",
    "Full Control over your Books and Audiobooks",
    "Standard turnaround 3 days or...",
    "24/7 Support",
    "Cancel when you want",
  ];
  const FEATURES_IMG = [
    "/endless.png",
    "/dashboard.png",
    "/fast.png",
    "/customer-service.png",
    "/cancel-contract.png",
  ];

  const navigate = useNavigate();
  useRedirectIfLoggedin();
  const handleClick = (e) => {
    e.preventDefault();
    navigate("/auth/signup");
  };
  return (
    <Container fluid className="pb-md-5">
      <Row>
        <Col className="navbar-container">
          <Container>
            <Navbar fixed="top" width="100%" className="p-0">
              <Container fluid className="navbar-container m-0">
                <Nav>
                  <Navbar.Brand href="/author">
                    <Image width="10%" src="/kdp-bird-trans.png" />
                    <b className="brand-text">Kdpcompliment</b>
                  </Navbar.Brand>
                </Nav>
                <Nav className="mt-1">
                  <Nav.Link className="mt-2" href="#features">
                    <p className="brand-text">Features</p>
                  </Nav.Link>
                  <Nav.Link className="mt-2" href="#faq">
                    <p className="brand-text">FAQ's</p>
                  </Nav.Link>
                  <Nav.Link className="mt-2" href="#pricing">
                    <p className="brand-text">Pricing</p>
                  </Nav.Link>
                  {window.innerWidth >= 768 && (
                    <>
                      <Nav.Link className="mt-2" href="/auth/login">
                        <p className="brand-text">Login</p>
                      </Nav.Link>
                      <Nav.Link>
                        <Button onClick={handleClick}>Try for free</Button>
                      </Nav.Link>
                    </>
                  )}
                </Nav>
              </Container>
            </Navbar>
          </Container>
        </Col>
      </Row>
      <Row xs={1}>
        <Col xs={0} md={2}></Col>
        <Col
          className="px-5 px-md-0 mt-5 pt-5 text-center text-md-start"
          xs={12}
          md={4}
        >
          <h2 className="login-heading mt-md-5 pt-md-5">
            <b>Boost your Amazon book's visibility with quality reviews</b>
          </h2>
          <p className="mt-4">
            The all-in-one platform for increasing your book & audiobook reviews
            on Amazon
          </p>
          <Button className="mt-4" variant="warning" onClick={handleClick}>
            Try For Free
          </Button>
        </Col>
        <Col xs={12} md={6} className="mt-md-5 pt-md-5">
          <div className="mt-md-4">
            <Image
              rounded
              className="ms-5 mt-5"
              width="80%"
              src="/review_graphic.png"
            />
          </div>
        </Col>
      </Row>
      <Row xs={1} md={12} className="mt-md-5 pt-5 justify-content-center ">
        <Col xs={"auto"} className="text-center">
          <h4 className="light-text login-heading">
            <b>How Kdpcompliment works in three simple steps</b>
          </h4>
        </Col>
      </Row>
      <Row xs={1} lg={4} className="mt-5 justify-content-center">
        <Col xs={9} sm={3} className="px-4">
          <Card className="customCard">
            <Card.Body className="text-center">
              <Image
                className="p-3 p-md-0"
                roundedCircle
                width="60%"
                src="/1_vector.png"
              />
              <h5 className="mt-3 login-heading">
                <b>Add your Book or Audiobook</b>
              </h5>
              <p className="mt-3">
                Upload your book file or ACX codes to kdpcompliment
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={9} sm={3} className="px-4 mt-3 mt-md-0">
          <Card className="customCard">
            <Card.Body className="text-center">
              <Image
                className="p-3 p-md-0"
                roundedCircle
                width="60%"
                src="/2_vector.png"
              />
              <h5 className="mt-3 login-heading">
                <b>Earn Points</b>
              </h5>
              <p className="mt-3">
                Start reading books or listening to audiobooks and earn points
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={9} sm={3} className="px-4 mt-3 mt-md-0">
          <Card className="customCard">
            <Card.Body className="text-center">
              <Image
                className="p-3 p-md-0"
                roundedCircle
                width="60%"
                src="/3_vector.png"
              />
              <h5 className="mt-3 login-heading">
                <b>Get Compliments (reviews)</b>
              </h5>
              <p className="mt-3">
                Use your points to get compliments for your book & audiobook
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row id="features" className="mt-5 pt-md-5 justify-content-center">
        <Col xs={"auto"}>
          <h4 className="login-heading light-text">
            <b>Why Kdpcompliment is best for you?</b>
          </h4>
        </Col>
      </Row>
      {window.innerWidth >= 768 && (
        <Row className=" justify-content-center text-center">
          <Timeline position="alternate">
            {FEATURES_HEADING.map((heading, i) => (
              <TimelineItem key={heading}>
                <TimelineOppositeContent color="text.secondary">
                  <Image
                    className="mx-5 mt-3"
                    width={"25%"}
                    src={FEATURES_IMG[i]}
                    sizes="(max-width: 575.98px) 100vw, (max-width: 767.98px) 50vw, 33.3vw"
                  />
                </TimelineOppositeContent>
                <TimelineContent className="mt-5 pt-3 mx-5">
                  <div>
                    <h4 className="mt-3">
                      <b>{heading}</b>
                    </h4>
                    <p>{FEATURES_TEXT[i]}</p>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Row>
      )}
      {window.innerWidth < 768 && (
        <Row className="px-5 justify-content-center text-center">
          {FEATURES_HEADING.map((heading, i) => (
            <Card className="customCard mt-3" key={"feat" + i}>
              <Card.Body className="text-center">
                <Image
                  className="p-3 p-md-0"
                  roundedCircle
                  width="60%"
                  src={FEATURES_IMG[i]}
                />
                <h5 className="mt-3 login-heading">
                  <b>{heading}</b>
                </h5>
                <p className="mt-3">{FEATURES_TEXT[i]}</p>
              </Card.Body>
            </Card>
          ))}
        </Row>
      )}
      <Row xs={2} sm={3} className="px-5 pt-5 justify-content-center">
        <Col xs={"auto"}>
          <h4 className="login-heading light-text">
            <b>
              A trusted choice for over 1,000 Book Authors seeking consistent
              review support
            </b>
          </h4>
        </Col>
      </Row>
      <Row className="mx-5 mt-3 px-5">
        {REVIEW_QOUTES.map((list, i) => (
          <Col key={"list" + i}>
            {list.map((review, ind) => {
              return (
                <Card key={"review" + i + ind} className="customCard mb-3">
                  <Card.Body>
                    <p>❛❛ {review} ❜❜</p>
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        ))}
      </Row>
      <Row id="faq" className="px-5 pt-5 justify-content-center">
        <Col xs={"auto"}>
          <h4 className="login-heading light-text">
            <b>FAQ's</b>
          </h4>
        </Col>
      </Row>
      <Row className="px-md-5 mx-md-5 pt-5 justify-content-start">
        <Col className="px-5 mx-md-5" xs={"auto"}>
          {QUESTIONS.map((q, i) => (
            <div key={"faq" + i}>
              <b>{q}</b>
              <p className="mt-2">{ANSWERS[i]}</p>
              <hr></hr>
            </div>
          ))}
        </Col>
      </Row>
      <Row id="pricing" className="px-5 pt-5 justify-content-center">
        <Col xs={"auto"}>
          <h4 className="login-heading light-text">
            <b>Pricing</b>
          </h4>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="mx-auto" xs={8}>
          <Card className="p-4 mb-5 mx-auto">
            <Row className="mb-4">
              <Col sm={5}>
                <Row>
                  <Col sm={6}>
                    <Image width="100%" src="/review_graphic.png" />
                  </Col>
                </Row>
                <Row className="ms-2">
                  <Col sm={9}>
                    <h3>
                      <b>Unlimited Reviews for All Your Books</b>
                    </h3>
                  </Col>
                  <hr className="m-3 my-4" />
                  <h5>
                    Kdpcompliment offers a 14-day risk-free trial subscription
                  </h5>
                  <p>Then $0.99 per month</p>
                  <Button
                    className="mt-4"
                    variant="warning"
                    onClick={handleClick}
                  >
                    Start your free trial Now
                  </Button>
                </Row>
              </Col>
              {window.innerWidth >= 768 && (
                <Col className="text-center">
                  <div className="vr divider mt-5"></div>
                </Col>
              )}
              <Col md={6}>
                <Row>
                  <Col xs={9}>
                    <p className="my-4">
                      Boost your Amazon book's visibility with quality reviews.
                      The all-in-one platform for increasing your book &
                      audiobook reviews on Amazon
                    </p>
                  </Col>
                  {window.innerWidth >= 768 && (
                    <>
                      <hr className="m-3 my-4" />
                      <h5 className="mb-3">
                        Unlimited Books & Audiobooks $0.99 / Month
                      </h5>
                      {ADV.map((text, i) => (
                        <FeatureList
                          key={"adv" + i}
                          src="/check.svg"
                          text={text}
                        />
                      ))}
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="text-center">
        <Col className="m-4">
          <h4 className="mt-3"></h4>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
