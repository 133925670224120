import { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import AuthAPI from "../services/apis/authAPI";

const useLoggedInCheck = ()=>{
    const navigate = useNavigate();
    const checkLoggedInMutation = useMutation(()=>AuthAPI.check(),{
        onSuccess:(data,variables,context)=>{
          if(!data.loggedIn){
            navigate('/auth/login')
          }
        }
    });
    useEffect(()=>{
        checkLoggedInMutation.mutate();
    },[]);

    return null;
}

export default useLoggedInCheck;