import axios from './configs/axiosConfig';

export const StripeAPI = {
       saveCreditCard : (paymentMethodId)=>{
        return axios.post('/credit-cards', paymentMethodId);
       },

       charge:(chargeDetails)=>{
              return axios.post('/charge',chargeDetails);
       },
       createPaymentIntent:async (chargeDetails)=>{
              let result = await axios.post('/charge/create-payment-intent',chargeDetails);
              console.log(result);
              return result;
       },
       createCustomerSession:async (chargeDetails)=>{
              return axios.get('/charge/create-customer-session');      
       }

}