import axios from "./configs/axiosConfig"

const UserAPI = {
    getUser:async()=>{
        const response = await axios.get('/users');
        
        if(response?.data?._id){
            return response.data;
        }
    },
    getUserDetails: async()=>{
        return axios.get('/users/details');
    },
    getAllUsers: async () => {
        const response = await axios.get('/users');
        return response;
    },
    saveAmazonName: async (data)=>{
        return axios.patch('/users/save-amazon-profile',data)
    },
    saveAmazonReviewCountry: async (data)=>{
        return axios.patch('/users/save-amazon-country',data)
    }
}

export default UserAPI