import { Button, Container, Form, Image,  Row } from "react-bootstrap";
import "./customFileInput.css";
import { useRef } from "react";

const CustomFileInput = ({
  label,
  name,
  required,
  accept,
  onChange,
  onBlur,
}) => {
  const hiddenFileInput = useRef(null);
  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };
  return (
    <Container className="d-flex justify-content-center">
      <Row>
      <Button
        variant="outline-danger"
        className="d-flex align-items-center  justify-content-center upload-button my-3"
        onClick={handleClick}
      >
        <Row className="d-flex justify-content-center">
          <Image
            src="/file-upload.svg"
            className="upload-icon mb-2"
          />
          <b>Choose a file...</b>
        </Row>
      </Button>
      <Form.Control
        style={{ display: "none" }}
        ref={hiddenFileInput}
        name={name}
        required={required}
        type="file"
        accept={accept}
        onChange={onChange}
        onBlur={onBlur}
      /></Row>
    </Container>
  );
};

export default CustomFileInput;
