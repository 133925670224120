import axios from './configs/axiosConfig';

export const EmailVerificationAPI = {
    confirm: async (token)=>{
        return axios.post('/email-confirmation/verify',{token});
    },

    resendToken: async ()=>{
        return axios.post('/email-confirmation/resend-confirmation-link');
    }
}

