import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Separator from "../../components/separator";
import AuthAPI from "../../services/apis/authAPI";
import { useMutation } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get a specific query parameter

  const navigate = useNavigate();

  const [password, setPassword] = useState({
    newPassword: undefined,
    confirmPassword: undefined,
  });
  const [passwordValid, setPasswordValid] = useState(false);
  const [status, setStatus] = useState();

  const resetPasswordMutation = useMutation(
    (data) => AuthAPI.resetPassword(data),
    {
      onSuccess: async (data, variables, context) => {
        setStatus(data.data);
        if (data.data.success) {
          redirectAfter();
        }
      },
    }
  );

  const redirectAfter = () => {
    setTimeout(() => {
      navigate("/auth/login");
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = searchParams.get("token");
    console.log(token);
    if (!password.confirmPassword || !passwordValid || !token) return;

    console.log("SUBMIT");
    resetPasswordMutation.mutate({ token, password: password.confirmPassword });
  };

  const handleInput = async (e) => {
    e.preventDefault();
    let pass = password;
    pass[e.target.name] = e.target.value;
    setPassword(pass);

    if (!password.newPassword || !password.confirmPassword) {
      setPasswordValid(false);
      return;
    }

    if (pass.newPassword === pass.confirmPassword) {
      setPasswordValid(true);
      return;
    }
    setPasswordValid(false);
  };

  return (
    <div>
      <Container fluid className="p-5">
        <Row>
          <Col className="mx-auto" md={3}>
            <h2 className="login-heading">Reset Password</h2>
            <p className="login-heading">
              <a
                href="/auth/login"
                style={{ textDecoration: "none", color: "#3e38f5" }}
              >
                Login Instead?
              </a>
            </p>
            <Separator text={""} />
            <Form className="mt-4" onInput={handleInput}>
              <Form.Group className="mt-2" controlId="formNewPassword">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  name="newPassword"
                  type="password"
                  placeholder="Enter your password"
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formConfirmPassword">
                <Form.Label>Confirm new password</Form.Label>
                <Form.Control
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm your password"
                  isInvalid={!passwordValid}
                  isValid={passwordValid}
                />
                <Form.Control.Feedback type="valid">
                  <p className="success">Passwords Match!</p>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  <p className="error">Incorrect Password!</p>
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                style={{ backgroundColor: "#3e38f5" }}
                className="login-button mt-4 mb-3"
                variant="primary"
                onClick={handleSubmit}
                disabled={!passwordValid}
              >
                Reset Password
              </Button>
              <div className="text-center">
                {resetPasswordMutation.isLoading ? (
                  <p>Loading...</p>
                ) : resetPasswordMutation.isError ? (
                  <p className="error">
                    Error!{" "}
                    {resetPasswordMutation.error?.response?.data?.message}
                  </p>
                ) : resetPasswordMutation.isSuccess ? (
                  <>
                    {status.success ? (
                      <>
                        <p className="success">
                          Password updated successfully!
                        </p>
                        <p>Redirecting to login...</p>
                      </>
                    ) : (
                      <>
                        <p className="error">{status.message}</p>
                      </>
                    )}
                  </>
                ) : null}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
