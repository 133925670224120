import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate, useOutletContext } from "react-router-dom";

const ReaderDashboard = () => {
  const userDetails = useOutletContext();
  const navigate = useNavigate();
  const handleRedirect = (index) => {
    const { _id } =
      userDetails.data.userAssignments[index];
    
    navigate("/assignments/" + _id);
  };
  return (
    <Container className="p-5">
      <Row id="A" className="mt-2">
        <Col id="A1">
          <h4>Assignment Dashboard</h4>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          {userDetails?.data?.userAssignments.map((assignment, index, list) => {
            return (
              <Row key={index} className="my-2">
                <Col sm={9}>
                  <Card
                    className="p-3 m-2 customCard pointer-card"
                    onClick={() => handleRedirect(index)}
                  >
                    <Row id="B1a">
                      <Col
                        id="B1a1"
                        sm={2}
                        md={3}
                        lg={4}
                        className="text-center"
                      >
                        <Image src={assignment.book.cover} thumbnail />
                        <h5 className="points mt-2">
                          <b>P {assignment.points}</b>
                        </h5>
                      </Col>
                      <Col id="B1a2" className="my-2">
                        <h3 className="title-text">{assignment.book.title}</h3>
                        <h5>{assignment.book.genre.name}</h5>
                        {assignment.reviewType === "verified" ? (
                          <>
                            <h5 className="grey-text">
                              Requires Purchase{" "}
                              <span className="price-text">
                                {assignment.kindlePrice}
                              </span>
                            </h5>
                          </>
                        ) : (
                          <>
                            <h5 className="grey-text">Free Download </h5>
                            {assignment.reviewType === "kindle" ? (
                              <>
                                <h5 className="grey-text">
                                  Requires{" "}
                                  <span className="price-text">
                                    Kindle Unlimited
                                  </span>
                                </h5>
                              </>
                            ) : (
                              <>
                                {assignment.reviewType === "audible" ? (
                                  <>
                                    <h5 className="grey-text">
                                      Requires{" "}
                                      <span className="price-text">
                                        Audible
                                      </span>
                                    </h5>
                                  </>
                                ) : null}
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col sm={3}>
                  <h6 className=" review-text mt-4">Due on</h6>
                  <p className="mt-1">
                    {new Date(assignment.dueDate).toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                    })}
                  </p>
                  {assignment.submitted ? (
                    <Button
                      className="mt-2"
                      variant="success"
                      onClick={() => handleRedirect(index)}
                    >
                      {assignment.approved
                        ? "Review Approved"
                        : "Review Submitted"}
                    </Button>
                  ) : (
                    <Button
                      className="mt-2"
                      variant="primary"
                      onClick={() => handleRedirect(index)}
                    >
                      Submit Review
                    </Button>
                  )}
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default ReaderDashboard;
