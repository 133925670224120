import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const PrivacyPolicy = () => {
  const POINTS = [
    'This Privacy Policy governs the manner in which www.kdpcompliment.com ("the Site" or "we") collects, uses, maintains, and discloses information collected from users ("you" or "users") of the www.kdpcompliment.com website.',

    "1. Information Collection and Use",
    "We collect personal information when you use our services, including but not limited to your name, email address, and payment information. This information is used to provide and improve our services, personalize your experience, and communicate with you.",

    "2. Information Sharing",
    "We do not sell, trade, or rent users' personal identification information to others. However, we may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners and trusted affiliates for the purposes outlined above.",

    "3. Third-Party Websites",
    "Our website may contain links to third-party websites. These websites have their own privacy policies, and we are not responsible for the privacy practices or content of such websites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.",

    "4. Data Security",
    " We implement appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our site.",

    "5. Changes to Privacy Policy",
    "We reserve the right to update this Privacy Policy at any time. We encourage users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.",

    "6. Your Consent",
    "If you do not agree to this policy, please refrain from using our website. Your continued use of the site following the posting of changes to this policy will be deemed your acceptance of those changes.",

    "7. If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at support@kdpcompliment.com.",
  ];

  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col className="text-center">
            <Card className="m-5 p-3">
              <h2 className="login-heading">
                <b>Privacy Policy</b>
              </h2>
              <div className="m-4 p-4 scroll">
                <h5 className="login-heading light-text">
                  <b>Privacy Policy for Kdpcompliment</b>
                </h5>
                {POINTS.map((line, ind) => (
                  <p key={"point" + ind}>{line}</p>
                ))}
                <h6 className="light-text">
                  <b>Last Updated: 24.03.2024</b>
                </h6>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
