import { Button, Col, Container, Form, Image, Modal, Row } from "react-bootstrap";
import useLoggedInCheck from "../../hooks/useLoggedinCheck";
import { useQuery } from "react-query";
import BooksAPI from "../../services/apis/booksAPI";
import { useNavigate, useParams } from "react-router-dom";
import ReviewForm from "../../components/reviewForm";
import "./listedBook.css";
import { useContext, useEffect, useState } from "react";
import "../../global.css";
import UserContext from "../../contexts/user.context";
const ListedBook = () => {
  useLoggedInCheck();
  const {user} = useContext(UserContext)

  const navigate = useNavigate();

  const { bookId } = useParams();
  const [showVerificationModal,setShowVerificationModal] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const { error, data, isLoading, isError } = useQuery(
    ["listedBook", bookId],
    () => BooksAPI.getById(bookId),
    { refetchOnWindowFocus: false }
  );

  
  useEffect(()=>{
    if(user?.subscribed === false){
      setShowVerificationModal(true);
    }
  },[user]);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const handleReviewButtonClick = (e) => {
    e.preventDefault();
    setShowReviewForm(true);
  };

  const handleEditButtonClick = (e) => {
    e.preventDefault();
    navigate("/book/edit/" + bookId);
  };

  const handleCloseModal=()=>{
    setShowVerificationModal(false)
  }

  const handleRedirectToSub=()=>{
    handleCloseModal();
    navigate("/account/billing");
  }

  return (
    <Container className="p-5">
      <Row id="A" className="mt-2">
        <Col id="A1">
          <h4>Your Book</h4>
        </Col>
      </Row>
      <Row id="B" sm={1} lg={2} className="my-3">
        <Col id="B1">
          <Row id="B1a">
            <Col id="B1a1" sm={2} md={3} lg={4}>
              <Image src={data.cover} thumbnail />
            </Col>
            <Col id="B1a2" className="my-2">
              <h2 className="title-text">{data.title}</h2>
              <Row>
                <Col className="mt-2">
                  <Container className="d-flex inline p-0">
                    <Image src="/reviews.svg" width={"20%"} className="me-2" />
                    <h5 className="review-text">
                      {data.ratingCount ? data.ratingCount : 0} reviews
                    </h5>
                  </Container>
                </Col>
                <Col className="mt-2">
                  <Container className="d-flex inline p-0">
                    <Image src="/trend.svg" width={"20%"} className="me-2" />
                    <h5 className="rating-text">
                      {data.ratingAvg ? data.ratingAvg: 0} avg rating
                    </h5>
                  </Container>
                </Col>
              </Row>
            </Col>
          </Row>
          {showReviewForm ? (
            <>
              <ReviewForm bookData={data} successCallback={()=>{setShowReviewForm(false)}}/>
            </>
          ) : null}
        </Col>
        <Col id="B2" className="px-4">
          <Row>
            
            {data.approved?
            
            <Button
              id="get-reviewed-btn"
              variant="success"
              as={Col}
              sm={3}
              className="m-1"
              onClick={handleReviewButtonClick}
            >
              GET REVIEWED
            </Button>:
            
            <Button
              id="not-approved-btn"
              variant="primary"
              as={Col}
              sm={4}
              className="m-1"
            >
              Pending Approval
            </Button>
            }
            <Button
              as={Col}
              sm={3}
              className="m-1 edit-button"
              onClick={handleEditButtonClick}
            >
              <div className="">EDIT</div>
            </Button>
          </Row>
          <Row className="my-4">
            <Col>
              <Row>
                <Col>
                  <p>
                    <b>Message to Readers</b>
                  </p>
                </Col>
                <Col>
                  <Button
                    size="sm"
                    variant="success"
                    className="w-auto mb-1"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <Form noValidate name="message">
                <Form.Group as={Row} controlId="messageForReaders">
                  <Col sm={8}>
                    <Form.Control
                      size="lg"
                      name="messageForReaders"
                      as="textarea"
                      required
                      minLength={15}
                      placeholder="Leave a message for readers"
                      defaultValue={data.messageForReaders}
                    ></Form.Control>
                    <Form.Control.Feedback type="inValid">
                      <p className="light-text">
                        Help the readers pick your book
                      </p>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal centered show={showVerificationModal} onHide={handleCloseModal}>
            <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="text-center">
            <Image width="40%" src="/review_graphic.png" />
            <h3 className="mt-4">No Active Subscriptions</h3>
            <Button className="mt-2 mb-3" onClick={handleRedirectToSub}>View Latest Plans</Button>
          </Modal.Body>
        </Modal>
    </Container>
  );
};

export default ListedBook;
