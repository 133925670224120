import { useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import UserContext from "../../contexts/user.context";
import BackButton from "../../components/backButton";
import StripePricingTable from "../../components/stripePricingTable";
// import StripePayment from "../../components/stripePayment";

const BillingPage = () => {
  const { user } = useContext(UserContext);


  return (
    <Container fluid className="profile-page-info p-5">
      <Row className="justify-content-center">
        <Col sm={6}>
          <BackButton />

          {user ? (
            <Card className="customCard">
              {console.log(user)}
              <Card.Header>
                <Card.Title className="mt-2">Billing Info</Card.Title>
              </Card.Header>
              <Card.Body className="p-0 m-0">
                {user.subscriptionId ? (
                  <div className="m-5">
                    <Card.Title>
                      Subscription Status:{" "}
                      {user.subscribed?<b style={{ color: "lightgreen" }}>Active</b>:<b style={{ color: "red" }}>Inactive</b>}
                      
                    </Card.Title>
                    <Link
                      to="https://billing.stripe.com/p/login/test_00g3fbaefchr7XW144"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="mt-3" type="submit">
                        Manage Billing
                      </Button>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <StripePricingTable />
                  </div>
                )}
              </Card.Body>
              <Card.Footer className="pb-3">
                For any help please email us at support@kdpcompliment.com
              </Card.Footer>
            </Card>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default BillingPage;
