import axios from "./configs/axiosConfig"

const BooksAPI = {
    getAllBooks: async () => {

        const response = await axios.get('/books');
        return response;

    },

    create: async (book) => {
        const response = await axios.post('/books',book);
        if(response?.data?._id){
            return response.data;
        }
    },

    edit: async (book) => {
        const response = await axios.patch('/books/edit',book);
        return response;
    },

    getById: async (id) => {
        const response = await axios.get(`/books/${id}`);
        if(response?.data?._id){
            return response.data;
        }
        return Error;
    },

    submitForApproval:async(id)=>{
        const response = await axios.get('/books/submit/'+id);
        if(response?.data){
            console.log(response);
            return response.data;
        }
    }
}

export default BooksAPI;