import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReviewForm from "../../components/reviewForm";
import "./listedBook.css";
import { useNavigate, useOutletContext } from "react-router-dom";

const Dashboard = () => {
  const userDetails = useOutletContext();
  const navigate = useNavigate();
  const [showReviewForm, setShowReviewForm] = useState([]);

  useEffect(() => {
    setShowReviewForm(
      new Array(userDetails?.data?.userBooks.length).fill(false)
    );
  }, [userDetails]);

  const handleEditButtonClick = (index) => {
    if (userDetails.data.userBooks[index].approved) {
      setShowReviewForm((prevShowReviewForm) => {
        const updatedShowReviewForm = [...prevShowReviewForm];
        updatedShowReviewForm[index] = !updatedShowReviewForm[index];
        return updatedShowReviewForm;
      });
    }
  };

  const handleCardClick = (index) => {
    if(showReviewForm[index]) return
    const { approved, submitForApproval, _id } = userDetails.data.userBooks[index];
    
    if (approved || submitForApproval) {
      navigate("/book/view/"+_id);
      return;
    }
    navigate("/book/edit/" + _id);
  };
  
  return (
    <Container className="p-5">
      <Row id="A" className="mt-2">
        <Col id="A1">
          <h4>My Dashboard</h4>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          {userDetails?.data?.userBooks.map((book, index, books) => {
            return (
              <Row key={index} className="my-2">
                <Col sm={9}>
                  <Card
                    className="p-3 m-2 customCard pointer-card"
                    onClick={() => handleCardClick(index)}
                  >
                    <Row id="B1a">
                      <Col id="B1a1" sm={2} md={3} lg={4}>
                        <Image src={book.cover} thumbnail />
                      </Col>
                      <Col id="B1a2" className="my-2">
                        <h3 className="title-text">{book.title}</h3>
                        {book.approved ? (
                          <Row className="justify-content-sm-start mt-2">
                            <Col sm={4} className="mt-2">
                              <Container className="d-flex inline p-0">
                                <Image
                                  src="/reviews.svg"
                                  width={"20%"}
                                  className="me-2"
                                />
                                <h6 className="review-text">
                                  {book.ratingCount ? book.ratingCount : 0}{" "}
                                  reviews
                                </h6>
                              </Container>
                            </Col>
                            <Col sm={7} className="mt-2">
                              <Container className="d-flex inline p-0">
                                <Image
                                  src="/trend.svg"
                                  width={"10%"}
                                  className="me-2"
                                />
                                <h6 className="rating-text">
                                  {book.ratingAvg ? book.ratingAvg : 0}{" "}
                                  avg rating
                                </h6>
                              </Container>
                            </Col>
                          </Row>
                        ) : (
                          <h6 className="light-title">Draft</h6>
                        )}
                      </Col>
                    </Row>
                    {showReviewForm[index] ? (
                      <Row>
                        <ReviewForm bookData={book} successCallback={() => handleEditButtonClick(index)}/>
                      </Row>
                    ) : null}
                  </Card>
                </Col>
                <Col sm={3}>
                  <h6 className=" review-text mt-3">Added on</h6>
                  <p className="mt-1 ">
                    {new Date(book.createdAt).toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                    })}
                  </p>
                  {book.approved ? (
                    <Button
                      className="mt-2"
                      variant="success"
                      onClick={() => handleEditButtonClick(index)}
                    >
                      Get reviewed
                    </Button>
                  ) : null}
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
