import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import { useMutation } from "react-query";
import AuthAPI from "../services/apis/authAPI";
import { useNavigate } from "react-router-dom";
import "./login.css";
import Separator from "../components/separator";
import { useContext } from "react";
import UserContext from "../contexts/user.context";

function Login() {
  const navigate = useNavigate();
  const { refreshUser } = useContext(UserContext);

  const loginWithPassword = async (credential) => {
    return await AuthAPI.login(credential);
  };

  const loginMutation = useMutation(loginWithPassword, {
    onSuccess: async (data, variables, context) => {
      console.log("Logged-In");
      refreshUser();
      navigate("/author");
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    const formEntries = new FormData(e.target).entries();
    for (const entry of formEntries) {
      data[entry[0]] = entry[1];
    }
    data.username = data.email;
    loginMutation.mutate(data);
  };

  return (
    <>
      <div>
        <Container fluid className="p-5">
          <Row>
            <Col className="mx-auto" md={3}>
              <h2 className="login-heading">Login</h2>
              <p className="login-heading">Hi, Welcome back 👋</p>
              <Separator text={"Login with Email"} />
              <Form className="mt-4" onSubmit={handleSubmit}>
                <Form.Group className="mb-2" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="E.g. johndoe@gmail.com"
                  />
                  <Form.Text className="text-muted">
                    Please enter registered email
                  </Form.Text>
                </Form.Group>
                <Form.Group className="" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                  />
                </Form.Group>
                <p className="mt-1 text-right">
                  <a
                    href="/auth/forgot-password"
                    style={{ textDecoration: "none", color: "#3e38f5" }}
                  >
                    Forgot Password?
                  </a>
                </p>
                <Button
                  style={{ backgroundColor: "#3e38f5" }}
                  className="login-button mt-2 mb-3"
                  variant="primary"
                  type="submit"
                >
                  Login
                </Button>
                <div className="text-center">
                  {loginMutation.isLoading ? (
                    <p>Loading...</p>
                  ) : loginMutation.isError ? (
                    <p className="error">
                      Incorrect Email or Password
                    </p>
                  ) : loginMutation.isSuccess ? (
                    <p className="success">You are logged in!</p>
                  ) : null}
                </div>
              </Form>
              <p className="text-center">
                Not registered yet?{" "}
                <a
                  href="/auth/signup"
                  style={{ textDecoration: "none", color: "#3e38f5" }}
                >
                  Create an account
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
