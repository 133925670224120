import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./pages/signup";
import Dashboard from "./pages/Author/dashboard";
import AddBook from "./pages/Author/addBook";
import ListedBook from "./pages/Author/listedBook";
import ReaderDashboard from "./pages/Reader/dashboard";
import ReadBook from "./pages/Reader/readBook";
import Library from "./pages/Reader/library";
import Layout from "./pages/layout";
import Login from "./pages/login";
import Logout from "./pages/logout";
import { useEffect } from "react";
import { useMutation } from "react-query";
import UserAPI from "./services/apis/userAPI";
import UserContext from "./contexts/user.context";
import Home from "./pages/home";
import ProfilePage from "./pages/Account/profilePage";
import BillingPage from "./pages/Account/billingPage";
import BasicLayout from "./pages/basicLayout";
import EmailVerification from "./pages/emailVerification";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import ForgotPassword from "./pages/Auth/forgotPassword";
import ResetPassword from "./pages/Auth/resetPassword";

function App() {
  const userMutation = useMutation(() => UserAPI.getUser());
  useEffect(() => {
    userMutation.mutate();
  }, []);
  const refreshUser = () => {
    userMutation.mutate();
  };

  return (
    <UserContext.Provider value={{ user: userMutation.data, refreshUser }}>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="author" element={<Dashboard />} />
            <Route path="book/add" element={<AddBook />} />
            <Route path="book/edit/:bookId" element={<AddBook />} />
            <Route path="book/view/:bookId" element={<ListedBook />} />
            <Route path="reader" element={<ReaderDashboard />} />
            <Route path="assignments/:assignmentId" element={<ReadBook />} />
            <Route path="library/browse" element={<Library />} />
          </Route>
          <Route element={<BasicLayout />}>
            <Route path="account/billing" element={<BillingPage />} />
            <Route path="account/profile" element={<ProfilePage />} />
          </Route>
          <Route element={<BasicLayout noUser={true} />}>
            <Route path="/auth/signup" element={<Signup />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/logout" element={<Logout />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Route>
          <Route path="/confirm-email" element={<EmailVerification />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
