import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Card,
  Image,
  Container,
} from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import BooksAPI from "../../services/apis/booksAPI";
import { useNavigate, useParams } from "react-router-dom";
import FilesAPI from "../../services/apis/filesAPI";
import useLoggedInCheck from "../../hooks/useLoggedinCheck";
import "./addBook.css";
import Heading from "../../components/book-form/heading";
import CustomFileInput from "../../components/form/customFileInput";
import CustomProgressBar from "../../components/form/blueProgressBar";
import { GenresAPI } from "../../services/apis/genresAPI";

const COVER_IMAGE_EXT = ".jpg, .png, .jpeg";
const BOOK_FILE_EXT = ".epub, .pdf";
const COVER_IMAGE_SIZE = 1024 * 1024 * 20;

const AddBook = () => {
  useLoggedInCheck();
  const navigate = useNavigate();
  const { bookId } = useParams();
  const formRef = useRef(null);

  const [bookData, setBookData] = useState({
    id: null,
    title: null,
    author: null,
  });
  const [progress, setProgress] = useState();

  const [completion, setCompletion] = useState(0);
  const [newSaved, setNewSaved] = useState(false);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState({
    part1: true,
    part2: false,
    part3: false,
    part4: false,
  });
  useEffect(() => {}, [progress]);

  const genreQuery = useQuery("genreList", GenresAPI.getAll);

  const getBookMutation = useMutation((bookId) => BooksAPI.getById(bookId), {
    onSuccess: (data, variables, context) => {
      setBookData({ ...data, id: data._id });
      formRef.current.reset();
      handleContinue();
    },
  });

  const createBookMutation = useMutation(
    (book) => {
      return BooksAPI.create(book);
    },
    {
      onSuccess: (data, variables, context) => {
        setNewSaved(true);
        navigate(`/book/edit/${data._id}`);
        setBookData({ ...bookData, id: data._id });
      },
    }
  );

  const editBookMutation = useMutation(
    (book) => {
      return BooksAPI.edit(book);
    },
    {
      onSuccess: (data, variables, context) => {
        handleContinue();
      },
    }
  );

  const onUploadProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const uploadFileMutation = useMutation(
    ({ formData, field }) => FilesAPI.upload(formData, field, onUploadProgress),
    {
      onSuccess: (data, { formData, field }, context) => {
        setBookData({ ...bookData, [field]: data });
        setProgress(0);
      },
    }
  );

  const submitBookMutation = useMutation((bookId) => BooksAPI.submitForApproval(bookId),
  {
    onSuccess:(data,bookId,context)=>{
      navigate('/book/view/'+bookId)
    }
  });

  useEffect(() => {
    const editTimeOutId = setTimeout(() => {
      if (bookData.id) {
        editBookMutation.mutate(bookData);
      }
    }, 1500);
    return () => clearTimeout(editTimeOutId);
  }, [bookData]);

  //Fetch data for bookId
  useEffect(() => {
    if (!bookId) {
      formRef.current.reset();
      setBookData({ id: null });
      setValidated(false);
      setNewSaved(false);
      setShow({
        part1: true,
        part2: false,
        part3: false,
        part4: false,
      });
      return;
    }
    //add query for fetching data
    getBookMutation.mutate(bookId);
  }, [bookId]);

  const onInput = async ({ target }) => {
    setBookData({
      ...bookData,
      [target.name]: target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setCompletion(100);
    submitBookMutation.mutate(bookData.id);
  };

  const handleBlur = (event) => {
    event.preventDefault();
    handleContinue();
  };

  const handleContinue = () => {
    setValidated(true);
    const showUpdate = { part1: true };
    if (formRef?.current?.checkValidity() || show.part2) {
      showUpdate.part2 = true;
      setCompletion(35);
    }
    if (
      bookData.standard ||
      bookData.onAudible ||
      bookData.forKindleUnlimited ||
      bookData.forVerifiedPurchase ||
      show.part3
    ) {
      showUpdate.part3 = true;
      setCompletion(55);
    }
    if (bookData.amazonUrl || bookData.genre || show.part4) {
      showUpdate.part4 = true;
      setCompletion(80);
    }

    setShow(showUpdate);
  };

  const handleTitleInputBlur = (e) => {
    e.preventDefault();
    if (bookId) return;
    if (newSaved) return;
    createBookMutation.mutate(bookData);
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    if (event.target.files[0].size > COVER_IMAGE_SIZE) {
      alert("File size should be below 20 MB!");
      event.target.value = null;
      return;
    }
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    uploadFileMutation.mutate({ formData, field: event.target.name });
  };

  const removeFileUrl = (field) => {
    setBookData({ ...bookData, [field]: null });
  };

  const handleCheckboxChange = (event) => {
    setBookData({ ...bookData, [event.target.name]: event.target.checked });
  };

  return (
    <Container fluid className="p-0">
      <Container className="d-flex align-items-center justify-content-around head">
        <h4 onClick={() => console.log(show)}>New Book</h4>
        <h6 className="light-text completion-text">{completion}% Complete</h6>
        <div className="light-text-thick">
          {getBookMutation.isLoading ? (
            <h6>Loading...</h6>
          ) : getBookMutation.isError ? (
            <h6>Error: {getBookMutation.error.message}</h6>
          ) : null}
          {createBookMutation.isLoading || editBookMutation.isLoading ? (
            <h6>Saving...</h6>
          ) : editBookMutation.isError || createBookMutation.isError ? (
            <h6>Error: Try after some time</h6>
          ) : editBookMutation.isSuccess || createBookMutation.isSuccess ? (
            <h6>Saved</h6>
          ) : null}
        </div>
      </Container>
      <Row className="page-container mt-3">
        <Col>
          <Form
            ref={formRef}
            noValidate
            validated={validated}
            name="Part1"
            onBlur={handleBlur}
          >
            <Form.Group as={Row} className="my-3 mx-2" controlId="title">
              <Form.Label column sm={2}>
                <Heading text="Title" />
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  size="lg"
                  name="title"
                  required
                  minLength={3}
                  placeholder="Enter a book title"
                  defaultValue={bookData.title}
                  onBlur={handleTitleInputBlur}
                  onInput={onInput}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Title is too short
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  <p className="light-text">
                    Provide the full title of your book
                  </p>
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="my-3 mx-2" controlId="author">
              <Form.Label column sm={2}>
                <Heading text={"Author"} />
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="author"
                  size="lg"
                  required
                  minLength={3}
                  placeholder="Provide books author"
                  defaultValue={bookData.author}
                  onInput={onInput}
                />
                <Form.Control.Feedback type="invalid">
                  Author is too short
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  <p className="light-text">Provide the author of your book.</p>
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Row className="justify-content-center mt-5">
              <Row className="m-4 mx-5">
                <Col className="mx-1" sm={8}>
                  <Heading text="Upload Your Front Cover" />
                </Col>
              </Row>
              {uploadFileMutation.isLoading ? (
                <>
                  <h6>Uploading...</h6>
                  <CustomProgressBar progress={progress} />
                </>
              ) : bookData.cover ? (
                <Col sm={3} className="text-center">
                  <Row className="justify-content-center">
                    <Image src={bookData.cover} />
                  </Row>
                  <Row className="justify-content-center">
                    <h6
                      className="text-decoration-underline my-2 remove-button"
                      onClick={() => removeFileUrl("cover")}
                    >
                      Remove File
                    </h6>
                  </Row>
                </Col>
              ) : (
                <Container className="d-flex justify-content-center">
                  <Col sm={6}>
                    {uploadFileMutation.isError ? (
                      <h6>Please try again!</h6>
                    ) : null}

                    <Form.Group className="my-3 mx-4" controlId="cover">
                      <CustomFileInput
                        name={"cover"}
                        required={true}
                        accept={COVER_IMAGE_EXT}
                        onChange={handleFileChange}
                        onBlur={handleBlur}
                      />
                      <div className="info-text">
                        <b>Accepted file types: .jpg, .png, .gif</b>
                        <p className="mt-2">
                          Please provide the 2D image of your front cover only.
                          We do not accept 3D models or front-spine-back covers.
                          To grab your exact Amazon cover, you can go to your
                          book's Amazon page, then right-click on the cover
                          image, and click 'Save image as.'
                        </p>
                      </div>
                      {/* <Form.Control
                      name="cover"
                      required
                      type="file"
                      accept={COVER_IMAGE_EXT}
                      onChange={handleFileChange}
                      onBlur={handleBlur}
                    /> */}
                    </Form.Group>
                  </Col>
                </Container>
              )}
            </Row>
          </Form>
          {!show.part2 ? (
            <Row className="justify-content-center">
              <Button
                variant="primary"
                className="w-auto my-3"
                onClick={handleContinue}
              >
                Continue
              </Button>
            </Row>
          ) : null}

          {show.part2 ? (
            <>
              <Form ref={formRef} noValidate validated={validated} name="Part2">
                <Row className="justify-content-center mx-1">
                  <Heading className="my-4" text={"Choose your readers"} />
                  <Col md={8}>
                    <Row className="justify-content-center my-2">
                      <Col md={10}>
                        <p>
                          Choose one or more of the options below. No matter
                          which options you select, all reviews are posted
                          directly to Amazon.com by your readers.
                        </p>
                      </Col>
                    </Row>
                    <Card className="m-4 customCard">
                      <Card.Body>
                        <Card.Title>
                          <Form.Check>
                            <Form.Check.Input
                              name="standard"
                              type="checkbox"
                              id="standardCheckbox"
                              defaultChecked={bookData.standard}
                              onChange={handleCheckboxChange}
                            />
                            <Form.Label className="mx-2 standard-option">
                              Standard
                            </Form.Label>
                          </Form.Check>
                        </Card.Title>
                        <Card.Text className="mx-4">
                          All readers can review your book using a free copy
                          that you provide to read.
                        </Card.Text>
                        {bookData.standard ? (
                          <Row className="justify-content-sm-center">
                            {uploadFileMutation.isLoading ? (
                              <>
                                <h6>Uploading...</h6>
                                <CustomProgressBar progress={progress} />
                              </>
                            ) : bookData.bookFile ? (
                              <Col sm={4}>
                                <Row className="justify-content-center">
                                  <h5>Book Uploaded!</h5>
                                </Row>
                                <Row className="justify-content-center">
                                  <h6
                                    className="text-decoration-underline my-2 remove-button"
                                    onClick={() => removeFileUrl("bookFile")}
                                  >
                                    Remove File
                                  </h6>
                                </Row>
                              </Col>
                            ) : (
                              <Col sm={10}>
                                {uploadFileMutation.isError ? (
                                  <h6>Please try again!</h6>
                                ) : null}
                                <Form.Group
                                  className="my-3 mx-2"
                                  controlId="bookFile"
                                >
                                  <Form.Label as={Row}>
                                    <b>Add your book file here</b>
                                  </Form.Label>
                                  <CustomFileInput
                                    name="bookFile"
                                    required={true}
                                    accept={BOOK_FILE_EXT}
                                    onChange={handleFileChange}
                                  />
                                </Form.Group>
                                <Card.Text className="my-2 mx-4">
                                  <b>Accepted file types: .epub, .pdf</b>
                                </Card.Text>
                                <Card.Text className="my-2 mx-4">
                                  Your book copy will be securely encrypted and
                                  only made accessible to readers who have
                                  already agreed to review your book.
                                </Card.Text>
                              </Col>
                            )}
                          </Row>
                        ) : (
                          <Card.Text className="my-2 mx-4">
                            <b>Requires a PDF or ePub copy of your book</b>
                          </Card.Text>
                        )}
                      </Card.Body>
                    </Card>
                    <Card className="m-4 customCard">
                      <Card.Body>
                        <Card.Title>
                          <Form.Check>
                            <Form.Check.Input
                              name="onAudible"
                              type="checkbox"
                              id="audibleCheckbox"
                              defaultChecked={bookData.onAudible}
                              onChange={handleCheckboxChange}
                            />
                            <Form.Label className="mx-2 audible-option">
                              Audible
                            </Form.Label>
                          </Form.Check>
                        </Card.Title>
                        <Card.Text className="mx-4">
                          All readers can review your book using a free copy
                          that you provide to listen on Audible.
                        </Card.Text>
                        {bookData.onAudible ? (
                          <Form.Group
                            as={Row}
                            className="my-3 mx-2"
                            controlId="audibleUrl"
                          >
                            <Form.Label sm={2}>
                              <b>Audible URL</b>
                            </Form.Label>

                            <Form.Control
                              name="audibleUrl"
                              required
                              minLength={3}
                              placeholder="Ex. https://www.audible.in/pd/You-Die-Alone-Audiobook/B0CBSSF8WR?action_code=ASSGB149080119000H&share_location=pdp"
                              defaultValue={bookData.audibleUrl}
                              onInput={onInput}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid URL
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">
                              Awesome!
                            </Form.Control.Feedback>
                          </Form.Group>
                        ) : (
                          <Card.Text className="my-2 mx-4">
                            <b>Requires audible book link</b>
                          </Card.Text>
                        )}
                      </Card.Body>
                    </Card>
                    <Card className="m-4 customCard">
                      <Card.Body>
                        <Card.Title>
                          <Form.Check>
                            <Form.Check.Input
                              name="forKindleUnlimited"
                              type="checkbox"
                              id="kindleCheckbox"
                              defaultChecked={bookData.forKindleUnlimited}
                              onChange={handleCheckboxChange}
                            />
                            <Form.Label className="mx-2 kindle-option">
                              Kindle Unlimited
                            </Form.Label>
                          </Form.Check>
                        </Card.Title>
                        <Card.Text className="mx-4">
                          Readers with a Kindle Unlimited subscription can
                          review your book. Your readers will read your book
                          using Kindle Unlimited.
                        </Card.Text>
                        <Card.Text className="my-2 mx-4">
                          <b>
                            Requires your book to be free for KU subscribers.
                          </b>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card className="m-4 customCard">
                      <Card.Body>
                        <Card.Title>
                          <Form.Check>
                            <Form.Check.Input

                              name="forVerifiedPurchase"
                              type="checkbox"
                              id="verifiedPurchaseCheckbox"
                              defaultChecked={bookData.forVerifiedPurchase}
                              onChange={handleCheckboxChange}
                            />
                            <Form.Label className="mx-2 verified-option">
                              Verified Purchase
                            </Form.Label>
                          </Form.Check>
                        </Card.Title>
                        <Card.Text className="mx-4">
                          Readers who agree to purchase your book on Amazon.com
                          can review your book. Your readers will read your book
                          using Kindle.
                        </Card.Text>
                        <Card.Text className="my-2 mx-4">
                          <b>
                            You have lifetime access to Verified Purchases
                            readers
                          </b>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </>
          ) : null}

          {!show.part3 && show.part2 ? (
            <Row className="justify-content-center">
              <Button
                variant="primary"
                className="w-auto mb-1"
                onClick={handleContinue}
              >
                Continue
              </Button>
            </Row>
          ) : null}
          {show.part3 ? (
            <Container className="mt-5 justify-content-center">
              <Row>
                <Col>
                  <Row className="mx-2">
                    <p className="info-text">
                      <b>Now for some easy things!</b> Point us to your book on
                      Amazon, and tell us the genre you're in.
                    </p>
                  </Row>
                  <Form noValidate validated={validated} name="Part3">
                    <Form.Group
                      as={Row}
                      className="my-3 mx-2"
                      controlId="amazonUrl"
                    >
                      <Form.Label column sm={3}>
                        <Heading text="Amazon URL" />
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          size="lg"
                          name="amazonUrl"
                          required
                          minLength={3}
                          placeholder="Enter your product URL"
                          defaultValue={bookData.amazonUrl}
                          onInput={onInput}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Enter a valid URL
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="valid">
                          <p className="light-text">
                            Provide the full URL to your book on Amazon.
                          </p>
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="my-3 mx-2"
                      controlId="genre"
                    >
                      <Form.Label column sm={3}>
                        <Heading text="Genre" />
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Select
                          name="genre"
                          required
                          defaultValue={bookData.genre}
                          onChange={onInput}
                          isInvalid={
                            !bookData.genre || bookData.genre === "select"
                          }
                        >
                          <option value={null}></option>
                          {genreQuery.isSuccess
                            ? genreQuery.data.data.map((genre, index, list) => {
                                return (
                                  <option key={genre._id} value={genre._id}>
                                    {genre.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Select a genre for the book
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="valid">
                          <p className="light-text">
                            Select the genre of your book.
                          </p>
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Container>
          ) : null}
          {!show.part4 && show.part3 ? (
            <Row className="justify-content-center mb-3">
              <Button
                variant="primary"
                className="w-auto mb-1"
                onClick={handleContinue}
              >
                Continue
              </Button>
            </Row>
          ) : null}
          {show.part4 ? (
            <Container className="my-5 justify-content-center">
              <Row>
                <Col>
                  <Row className="mx-2">
                    <p>
                      <b>Summarize the book in two or more sentences.</b>
                    </p>
                  </Row>
                  <Form noValidate validated={validated} name="Part4">
                    <Form.Group
                      as={Row}
                      className="my-3 mx-2"
                      controlId="summary"
                    >
                      <Col sm={8}>
                        <Form.Control
                          size="lg"
                          name="summary"
                          as="textarea"
                          required
                          minLength={15}
                          placeholder="Help the readers pick your book"
                          defaultValue={bookData.summary}
                          onInput={onInput}
                        ></Form.Control>
                        <Form.Control.Feedback type="inValid">
                          <p className="light-text">
                            Help the readers pick your book
                          </p>
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row className="justify-content-center mb-3">
                <Button
                  variant="success"
                  className="w-auto mb-1"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Row>
            </Container>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default AddBook;
