import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";

const BackButton=()=>{
  const navigate = useNavigate();
  
  const handleBackClick = (e)=>{
    navigate("/author")
  }
    return (<div className="mb-4 back-button" onClick={handleBackClick}> 
    <Image src="/previous.png" width="5%" className="me-1"/> Back to Dashboard
    </div>)
}

export default BackButton;