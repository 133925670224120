import { useNavigate, useParams } from "react-router-dom";
import useLoggedInCheck from "../../hooks/useLoggedinCheck";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import AssignmentAPI from "../../services/apis/assignmentAPI";
import { useMutation, useQuery } from "react-query";
import "./readBook.css";
import { TodosAPI } from "../../services/apis/todosAPI";
import { useContext, useEffect, useState } from "react";
import DayCounter from "../../components/read-book/dayCounter";
import UserContext from "../../contexts/user.context";

const RATING_VALUES = [1, 2, 3, 4, 5];

const ReadBook = () => {
  useLoggedInCheck();
  const {user} = useContext(UserContext);
  const { assignmentId } = useParams();
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false);
  const [reviewForm, setReviewForm] = useState({ rating: 0 });

  const { error, data, isLoading, isError } = useQuery(
    ["assignment", assignmentId],
    () => AssignmentAPI.getById(assignmentId),
    { refetchOnWindowFocus: false }
  );

  const [todos, setTodos] = useState([]);

  const todosQuery = useQuery(["todos"], () => TodosAPI.get());

  const todosMutation = useMutation((data) => AssignmentAPI.updateTodos(data));
  useEffect(() => {
    if (todos.length && data) {
      todosMutation.mutate({ assignmentId, todos });
    }
  }, [todos, data, assignmentId]);
  useEffect(() => {
    if (data?.data?.todos?.length) {
      setTodos(data.data.todos);
    } else setTodos([false, false, false]);
  }, [data]);

  const handleTodosChange = (e) => {
    if (e.target.name === "2" && e.target.checked) {
      handleShowModal();
      return;
    }
    let updatedList = [...todos];
    updatedList[Number(e.target.name)] = e.target.checked;
    setTodos(updatedList);
  };

  const handleShowModal = () => {
    if(user?.amazonProfileName){
      setShowModal(true);
      return
    }
    navigate("/account/profile");
    alert("Please provide your amazon profile name used to submit review")
    
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const submitAssignmentMutation = useMutation(
    (data) => AssignmentAPI.submit(data),
    {
      onSuccess: (data, variables, context) => {
        window.location.reload();
        handleCloseModal();
      },
    }
  );
  const handleReviewFormSubmit = () => {
    submitAssignmentMutation.mutate({ assignmentId, reviewForm });
  };

  const handleFileDownload = (event) => {
    event.preventDefault();
    fetch(data.data.book.bookFile, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = data.data.book.title + ".pdf";

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleCopyLink = (event) => {
    event.preventDefault();
    let productUrl;
    switch (data.data.reviewType) {
      case "verified" || "kindle":
        productUrl = data.data.book.amazonUrl;
        break;
      case "audible":
        productUrl = data.data.book.audibleUrl;
        break;
      default:
        productUrl = null;
        break;
    }
    navigator.clipboard.writeText(productUrl);
    alert("The link is copied to your clipboard. You can paste it in a new tab.")
  };

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <Container className="m-0 p-4">
      <Row className="mt-2">
        <Col>
          <h5>Your Assignment</h5>
        </Col>
      </Row>
      <Row sm={1} lg={2} className="my-3">
        <Col>
          <Row>
            <Col className="text-center" sm={2} md={3} lg={4}>
              <Image
                className="assignment-cover-image"
                src={data.data.book.cover}
                rounded
              />
              <h5 className="points mt-2">
                <b>P {data.data.points}</b>
              </h5>{" "}
            </Col>
            <Col className="my-2">
              <h2 className="assignment-title-text">{data.data.book.title}</h2>

              <h5>{data.data.book.genre.name}</h5>
              {data.data.reviewType === "verified" ? (
                <>
                  <h5 className="grey-text">
                    Requires Purchase{" "}
                    <span className="price-text">{data.data.kindlePrice}</span>
                  </h5>
                </>
              ) : (
                <>
                  <h5 className="grey-text">Free Download </h5>
                  {data.data.reviewType === "kindle" ? (
                    <>
                      <h5 className="grey-text">
                        Requires{" "}
                        <span className="price-text">Kindle Unlimited</span>
                      </h5>
                    </>
                  ) : (
                    <>
                      {data.data.reviewType === "audible" ? (
                        <>
                          <h5 className="grey-text">
                            Requires <span className="price-text">Audible</span>
                          </h5>
                        </>
                      ) : null}
                    </>
                  )}
                </>
              )}
              <h5 className="grey-text mb-5">
                Due{" "}
                <span className="date-text">
                  {new Date(
                    new Date().setDate(new Date(data.data.dueDate).getDate())
                  ).toLocaleString("en-US", {
                    day: "2-digit",
                    month: "short",
                  })}
                </span>
              </h5>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className="hr" />
      {data?.data?.submitted ? (
        <h1>You have already submitted your review</h1>
      ) : (
        <>
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  <h5>Your Todos</h5>
                </Col>
              </Row>
              <Row>
                <Card className="p-3 m-2 customCard">
                  <Row>
                    <Col>
                      <Form.Group>
                        {todosQuery.data?.data[data?.data?.reviewType].map(
                          (value, index, list) => {
                            return (
                              <div key={index}>
                                <Form.Check>
                                  <Form.Check.Input
                                    className="mt-2 me-2"
                                    type="checkbox"
                                    name={index}
                                    checked={todos[index]}
                                    onChange={handleTodosChange}
                                  />
                                  <Form.Label>
                                    <h5 style={{ fontWeight: 400 }}>{value}</h5>
                                  </Form.Label>
                                </Form.Check>
                                {!index ? (
                                  <div className="mx-4 mb-3">
                                    {data.data.reviewType === "standard" ? (
                                      <>
                                        <Button onClick={handleFileDownload}>
                                          Download
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Button onClick={handleCopyLink}>
                                          Copy Link
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            );
                          }
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col className="d-flex justify-content-end align-items-start sm-3">
                    <Button onClick={handleFileDownload}>Download</Button>
                  </Col> */}
                  </Row>
                </Card>
              </Row>
            </Col>
            <Col>
              <Row className="mt-2">
                <Col>
                  <h5>Your Schedule</h5>
                  <Row className="justify-content-center mt-3">
                    <Col sm={10}>
                      <DayCounter
                        n={data.data.turnaroundDays}
                        lastDate={new Date(data.data.dueDate)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr className="hr" />
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  <h5>Book Summary</h5>
                </Col>

                <p>{data.data.book.summary}</p>
              </Row>
            </Col>
          </Row>
        </>
      )}

      <Modal centered size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="justify-content-center">
            Turn In Your Review
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form as={Row}>
            <Col sm={2}>
              <Form.Label>Your Rating</Form.Label>
            </Col>
            <Col>
              {RATING_VALUES.map((val, ind, list) => {
                return (
                  <Form.Check
                    inline
                    type="radio"
                    id={val}
                    key={val}
                    label={val + " Star"}
                    checked={reviewForm.rating === val}
                    onChange={() =>
                      setReviewForm({ ...reviewForm, rating: val })
                    }
                  />
                );
              })}
            </Col>
            <Form.Text>
              Please provide the number of stars you rated this book in your
              Amazon review.
            </Form.Text>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="success" onClick={handleReviewFormSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ReadBook;
