import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useMutation } from "react-query";
import AuthAPI from "../services/apis/authAPI";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { jwtDecode } from "jwt-decode";
import "./signup.css";
import FeatureList from "../components/featureList";

function Signup() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const createUser = (newUser) => {
    return AuthAPI.signup(newUser);
  };

  const createUserMutation = useMutation(createUser, {
    onMutate: (newUser) => {},
    onSuccess: (data, variables, context) => {
      console.log("SUCCESS");
      loginMutation.mutate({
        username: variables.username,
        password: variables.password,
      });
    },
  });
  const loginWithPassword = async (credential) => {
    return await AuthAPI.login(credential);
  };
  const setLoginCookie = (token) => {
    const decoded = jwtDecode(token);
    cookies.set("jwt_authentication", token, {
      expires: new Date(decoded.exp * 1000),
    });
  };
  const loginMutation = useMutation(loginWithPassword, {
    onSuccess: async (data, variables, context) => {
      console.log("Logged-In");
      setLoginCookie(data.access_token);
      navigate("/author");
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    const formEntries = new FormData(e.target).entries();
    for (const entry of formEntries) {
      data[entry[0]] = entry[1];
    }
    data.username = data.email;
    createUserMutation.mutate(data);
  };

  return (
    <div>
      <Container fluid className="p-2">
        <Row className="justify-content-center">
          <Col className="mx-auto text-center">
            <Image width="10%" src="/kdp-bird-trans.png" />
            <h3 className="mb-4">Create your account</h3>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto" xs={8}>
            <Card className="p-4 mb-5 mx-auto">
              <Row className="mb-4">
                <Col sm={5}>
                  <Row>
                    <Col sm={6}>
                      <Image width="100%" src="/review_graphic.png" />
                    </Col>
                  </Row>
                  <Row className="ms-2">
                    <Col sm={9}>
                      <h3>
                        <b>Unlimited Reviews for All Your Books</b>
                      </h3>
                    </Col>
                    <h5>Free for 14 days</h5>
                    <hr className="m-3 my-4" />
                    <h5 className="mb-3">Try risk-free</h5>
                    {window.innerWidth >= 768 && <div>
                      <FeatureList
                        src="/check.svg"
                        text="Unlimited Reviews for Each Book"
                      />
                      <FeatureList
                        src="/check.svg"
                        text="No Charge Per Review"
                      />
                      <FeatureList src="/check.svg" text="Cancel at Anytime" />
                      <FeatureList
                        src="/reading.svg"
                        text="Read the books you love"
                      />
                    </div>}
                  </Row>
                </Col>
                {window.innerWidth >= 768 &&<Col className="text-center">
                  <div className="vr divider mt-5"></div>
                </Col>}
                <Col md={6}>
                  <h5 className="my-4">Register for free now</h5>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        <b>Name</b>
                        <b className="mandatory"> *</b>
                      </Form.Label>
                      <Form.Control
                        name="name"
                        placeholder="Enter your full name"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        <b>Email</b>
                        <b className="mandatory"> *</b>
                      </Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        placeholder="Enter email"
                      />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        <b>Password</b>
                        <b className="mandatory"> *</b>
                      </Form.Label>
                      <Form.Control
                        name="password"
                        type="password"
                        placeholder="Password"
                      />
                      <Form.Text className="text-muted">
                        Use a strong password{" "}
                      </Form.Text>
                    </Form.Group>

                    <div className="d-grid text-center">
                      By clicking below, you agree to our{" "}
                      <a href="/terms-of-service">Terms of Service</a>&
                      <a href="/privacy-policy">Privacy policy.</a>
                      <Button className="mt-2" type="submit">
                        <b>Sign Up</b>
                      </Button>
                      {createUserMutation.isLoading ? (
                        <p>Loading...</p>
                      ) : createUserMutation.isError ? (
                        <p className="error">
                          Error!{" "}
                          {createUserMutation.error?.response?.data?.message}
                        </p>
                      ) : createUserMutation.isSuccess ? (
                        <p className="success">Successfully registered!</p>
                      ) : null}
                    </div>
                  </Form>
                  <p className="text-center mt-3">
                    Have an account?{" "}
                    <a
                      href="/auth/login"
                      style={{ textDecoration: "none", color: "#3e38f5" }}
                    >
                      Login in now
                    </a>
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Signup;
