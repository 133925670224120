import api from './configs/axiosConfig';

const AuthAPI = {
    login: async ({username, password})=>{
        // let logout = await api.post('/auth/logout');
        // if(logout?.data?.success){
        //     let response = await api.post('/auth/login',{username,password});
        //     console.log(response);
        //     return response.data;
        // }
        // return new Error("Couldn't logout")
        let response = await api.post('/auth/login',{username,password});
            console.log(response);
            return response.data;
    },

    check: async ()=>{
        let response = await api.get('/auth/check-logged-in');
        return response?.data;
    },

    signup: async (newUser)=>{
        let response = await api.post('/auth/signup',newUser);
        if(response?.data?._id){
            console.log("registered")
            return response.data;
        }
    },

    logout: async ()=>{
        let response = await api.post('/auth/logout');
        return response;
    },

    requestResetPassword: async (data)=>{
        return api.post('/auth/forgot-password',data);
    },

    resetPassword: async(data)=>{
        return api.post('/auth/reset-password',data);
    }
}

export default AuthAPI;