import React from 'react';
import './Separator.css'; // Import your CSS file

function Separator({ text }) {
  return (
    <div className="separator">
      <div className="line"></div>
      <div className="text">{text}</div>
      <div className="line"></div>
    </div>
  );
}

export default Separator;
