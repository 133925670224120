import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import VerificationCard from "../components/verificationCard";
import { useMutation } from "react-query";
import { EmailVerificationAPI } from "../services/apis/emailVerificationAPI";

const EmailVerification = () => {
  const [vereficationFailed, setVerificationFailed] = useState(false);
  const [resent, setResent] = useState(false);
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const emailMutation = useMutation(
    (token) => EmailVerificationAPI.confirm(token),
    {
      onSuccess: () => {
        navigate("/author");
      },
      onError: () => {
        setVerificationFailed(true);
      },
    }
  );

  const resendMutation = useMutation(() => EmailVerificationAPI.resendToken(), {
    onSuccess: () => {
      setResent(true);
    },
  });

  useEffect(() => {
    emailMutation.mutate(queryParams.get("token"));
  }, [ queryParams]);

  return (
    <Container
      style={{ height: "100vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      {resent ? (
        <h2>Thank you! Please checkout your mail for verification link</h2>
      ) : (
        <>
          {vereficationFailed ? (
            <VerificationCard
              text={"Your email verification link has expired!"}
              buttonName={"Resend Link"}
              buttonAction={() => {
                resendMutation.mutate();
              }}
            />
          ) : (
            <h2>Thank you!</h2>
          )}
        </>
      )}
    </Container>
  );
};

export default EmailVerification;
