import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button  from "react-bootstrap/Button"


const TermsOfService = ()=>{

    const TERMS = [
"1. These legal terms constitute a legally binding agreement made between me and you, whether personally or on behalf of an entity (‘you’), and Kdpcompliment, concerning your access to and use of the services. You agree that by accessing the services, you have read, understood, and agreed to be bound by all these legal terms. If you do not agree with all of these terms, then you are prohibited from using the service and you must discontinue use immediately.",

"2. We reserve the right, in our sole discretion, to make changes or modifications to these terms at any time, with or without notice. It is your responsibility to periodically review these legal terms to stay informed on updates. The services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the services.",

"3. We are the owner of all intellectual property rights in our services, including all source code, databases, functionality, software, website designs, and graphics in the services (collectively, the ‘content’), as well as the trademarks, service marks, and logos contained therein (the ‘marks’). The content and marks are provided in or through the services ‘AS IS’ for your personal, non-commercial use.",
    ]

    const COPYRIGHT =[
"1. We respect the intellectual property rights of others.",
"2. If you believe that any material available on or through the services infringes upon any copyright you own or control, please immediately get in touch via support@kdpcompliment.com",

    ]

    const RULES = [

"1. Users are restricted to one account per user. You are not able to have more than that.",

"2. This site will have links to other third-party websites such as Amazon & Audible. These third-party sites are solely responsible for the content contained within their websites. It is your responsibility to abide by the terms of use of these respective websites.",

"3. Kdpcompliment has not assessed the material of any third-party websites that are accessible through our platform, thus we cannot vouch for its validity or accuracy.",

"4. Due to the nature of our services, we let authors as well as publishers submit materials relating to their books/audiobooks, such as book covers, descriptions, audio samples, and codes for audiobooks. Due to the author-submitted nature of this work, we cannot guarantee ownership. Sadly, we are unable to guarantee the quality or accuracy of the information.",

"5. By utilizing our platform, you grant Kdpcompliment a perpetual, non-exclusive right to display information you submit for your book or audiobook submissions in order to promote your book and audiobook.",

"6. At any moment and without prior warning, Kdpcompliment reserves the right to remove any user from the platform. Subscription costs for the month of removal are not obliged to be refunded by Kdpcompliment. Kdpcompliment will not remove a user from the platform without a reason. Some of the reasons that will lead to your account being suspended include, but are not limited to: uploading sensitive content, leaving reviews using AI, and creating more than one account per user.",

"7. Kdpcompliment will only offer a full refund to users who request one within 48 hours of their most recent payment. To get a full refund, reach out to support@kdpcompliment.com",

"8. Reviewers must comply with Amazon's and Audible's reviewing policies and community guidelines.",

"9. By agreeing to our terms, you consent to having your email address collected. In accordance to our privacy policy, we will never share your personal information with a third party.",

"10. We cannot guarantee authors and publishers that their books and audiobooks will receive 4- and 5-star reviews. Reviewers are urged to be sincere in their opinions. There is no censorship of reviewers in any form.",
"11. Users’ Books and audiobooks must be live on sale on Amazon and Audible to be eligible. We do not accept pre-order books or books that are still in draft.",
"12. We cannot guarantee that the platform will operate bug-free or uninterrupted. Occasionally, there may be bugs or internal issues.If you come across any bugs, please let us know at support@kdpcompliment.com",

"13. Kdpcompliment are not liable for any direct or indirect damages that result from the use of, misuse of, or the inability to use our platform.",
"14. We make no guarantees for the perpetuity of reviews submitted by users on Amazon and Audible. Reviews on both Amazon and Audible could be removed for reasons including, but not limited to: users deleting their amazon/audible account, the review gets removed later on after it has been accepted, Amazon/Audible removing your ability to leave reviews, users deleting their review.",

"15. If you chose to sign up to our platform, make purchases via the site, or subscribe to one of our plans, you are agreeing to abide by all of the terms and conditions of these terms of service.",

"16. We make no guarantee that using our services will lead to an increase in book/audiobook sales or visibility.",

"17. We reserve the right to alter, stop or cancel any feature of the site or services at any time.",

"18. This agreement shall be governed by and construed in accordance with the laws of the United Kingdom, without giving effect to any principles of conflict of law.",

"19. You can contact us by email at support@kdpcompliment.com"

]
    
    return (
        <div>
            <Container>
                <Row className="justify-content-center">
                    <Col className="text-center">
                    <Card className="m-5 p-3">
                        <h2 className="login-heading"><b>
                            Terms of Service
                        </b></h2>
                        <div className="m-4 p-4 scroll">
                            <h5 className="login-heading light-text"><b>Terms Of Service Kdpcompliment</b></h5>
                            {TERMS.map((line,ind)=> <p key={"terms"+ind}>
                                {line}
                            </p>)}
                            <h5 className="login-heading light-text"><b>Copyright Infringment Kdpcompliment</b></h5>
                            {COPYRIGHT.map((line,ind)=> <p key={"copyright"+ind}>
                                {line}
                            </p>)}
                            <h5 className="login-heading light-text"><b>Rules Kdpcompliment</b></h5>
                            {RULES.map((line,ind)=> <p key={"rule"+ind}>
                                {line}
                            </p>)}
                        </div>
                    </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TermsOfService;