import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import AuthAPI from "../services/apis/authAPI";
import { useNavigate } from "react-router-dom";
import useLoggedInCheck from "../hooks/useLoggedinCheck";

const Logout = () => {
  useLoggedInCheck();
  const navigate = useNavigate();
  const logoutMutation = useMutation(() => AuthAPI.logout(), {
    onSuccess: (data, variables, context) => {
      navigate("/auth/login");
    },
  });

  const handleLogout = (event) => {
    event.preventDefault();
    logoutMutation.mutate();
  };

  return (
    <Container>
      <Row className="justify-content-center m-5">
        <Col sm={4} className="text-center">
            <Image className="mb-4" src="/leaving.png" width={"40%"}/>
          <h2 className="my-3 login-heading">Do you wish to logout?</h2>

          <Button className="my-2 mx-2" onClick={handleLogout}>
            Yes, Log me out!
          </Button>

          <b className="mx-2">
            <a href="/author">No, Cancel</a>
          </b>
        </Col>
      </Row>
    </Container>
  );
};

export default Logout;
