import { Button, Card, Col, Row } from "react-bootstrap";

const VerificationCard=({text,buttonName,buttonAction})=>{
    return (
        <Card className="customCard">
            <Card.Body>
                <Row className="text-center">
                    <Col>
                    <h4 className="m-4">{text}</h4>
                    <Button className="m-2" onClick={buttonAction}>{buttonName}</Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
export default VerificationCard;