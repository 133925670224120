import { useContext } from "react";
import "./navbar.css";
import {
  Container,
  Image,
  Nav,
  NavDropdown,
  Navbar
} from "react-bootstrap";
import UserContext from "../contexts/user.context";

const Header = ({ noUser }) => {
  const { user } = useContext(UserContext);
  return (
    <Container>
      <Navbar fixed="top" width="100%" className="p-0">
        <Container fluid className="navbar-container m-0">
          <Nav>
            <Navbar.Brand href="/author">
              <Image width="10%" src="/kdp-bird-trans.png" />
              <b className="brand-text">Kdpcompliment</b>
            </Navbar.Brand>
          </Nav>
          {!noUser && user ? (
            <div>
              <Nav>
                <Nav.Item>
                  <div className="me-4 points-text">
                    <b>Points: {user.points}</b>
                  </div>
                </Nav.Item>
                <NavDropdown
                  title={<Image width="40%" src="/user.png" />}
                  drop="start"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/account/profile">
                    My Account
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/account/billing">
                    Subscriptions
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/auth/logout">
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </div>
          ) : null}
        </Container>
      </Navbar>
    </Container>
  );
};

export default Header;
