import "./layout.css";
import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "../components/navbar";
import SidebarWrapper from "../components/sidebar";
import useLoggedInCheck from "../hooks/useLoggedinCheck";
import "./layout.css";
import { useMutation, useQuery } from "react-query";
import UserAPI from "../services/apis/userAPI";
import { blue, lightBlue } from "@mui/material/colors";
import { EmailVerificationAPI } from "../services/apis/emailVerificationAPI";
import { useEffect, useState } from "react";
const Layout = () => {
  useLoggedInCheck();
  const [showVerificationModal,setShowVerificationModal] = useState(false);
  const [resent, setResent] = useState(false);
  const [hideButton, setHideButton] = useState(false)
  const { error, data } = useQuery(["userDetails"], UserAPI.getUserDetails);
 
  useEffect(()=>{
    if(data?.data?.userDetails?.isEmailConfirmed === false){
      setShowVerificationModal(true);
    }
  },[data])

  const resendMutation = useMutation(() => EmailVerificationAPI.resendToken(), {
    onSuccess: () => {
      setResent(true);
    },
  });
  
  if (error) {
    return <h3>Error connecting at the moment..</h3>;
  }
  return (
    <>
      <Container fluid className="p-0">
        <Row>
          <Col className="navbar-container">
            <Header />
          </Col>
        </Row>
        <div className="app-content">
          <Row>
            <Col md={2} className="p-0 sidebar-col">
              <SidebarWrapper userDetails={data} />
            </Col>
            <Col className="outlet-col me-0">
              <Outlet context={data} />
            </Col>
          </Row>
        </div>
        <Modal centered show={showVerificationModal}>
          <Modal.Body className="text-center">
            <Image width="40%" src="/email-verify-icon.jpg" />
            <h3 className="m-2">Verify your email</h3>
            <p>
              We've sent an email to{" "}
              <b style={{ color: blue }}>{data?.data?.userDetails.email}</b> to
              verify your email address and activate your account. The link in
              the email will expire in 24 hours
            </p>
          </Modal.Body>
          <Modal.Footer>
            {resent ? (
              <p>Link Sent</p>
            ) : (!hideButton &&
              <Button size="sm" style={{color:lightBlue}}
                onClick={() => {
                  resendMutation.mutate();
                  setHideButton(true)
                }}
              >
                Resend Link
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default Layout;
